import React from 'react'

import filesize from 'filesize'
import moment from 'moment'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'

import CabalButton from 'global/CabalButton'
import { UploadButton } from 'global/Input'
import Typography from 'global/Typography'
import DataCardV2 from 'ui-components/DataCardV2'
import { cabalToast } from 'ui-components/Toast'
import Widget from 'ui-components/Widget'

import api, { callApi } from 'utils/api'

const FileRow = ({ file }: { file: any }) => {
  const { file_name, file_type, created_at, api_url, file_size } = file

  const description = [
    <>{moment(created_at).format('MMMM YYYY')}</>,
    <>{filesize(file_size)}</>,
    <>{file_type}</>,
  ]

  return (
    <DataCardV2
      avatar={<i className="fa-thin fa-file-pdf" />}
      title={file_name}
      className="hover:bg-transparent dark:hover:bg-transparent"
      description={description}
      cta={
        <CabalButton variant="link" padding={'0'} onClick={() => window.open(api_url, '_blank')}>
          View
        </CabalButton>
      }
    />
  )
}

const Files = ({ canEdit }: { canEdit: boolean }) => {
  const { slug } = useParams<{ slug: string }>()
  const queryClient = useQueryClient()
  const [limit, setLimit] = React.useState(3)

  const { data: files = [] } = useQuery(
    ['globalPersonFiles', slug],
    () => callApi(api.getFiles, { slug }),
    {
      enabled: !!slug,
    },
  )

  const associateFile = useMutation(
    (uploadUuid: string) =>
      callApi(api.uploadFile, {
        slug,
        upload_uuid: uploadUuid,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['globalPersonFiles', slug])
        cabalToast({ style: 'success', content: 'File uploaded successfully' })
      },
      onError: () => {
        cabalToast({ style: 'error', content: 'Failed to upload file' })
      },
    },
  )

  const visibleFiles = React.useMemo(() => files.slice(0, limit), [files, limit])

  if (files.length === 0 && !canEdit) {
    return null
  }

  return (
    <Widget
      title="Files"
      className="group"
      cta={
        canEdit && (
          <div className="group-hover:flex hidden flex-row gap-8 h-5">
            <UploadButton
              allowedExtensions={['pdf']}
              onUpload={(uploadUuid) => associateFile.mutate(uploadUuid)}
              text="Add"
              triggerProps={{
                variant: 'link',
                leftIcon: <i className="far fa-plus" />,
                padding: '0',
              }}
            />
          </div>
        )
      }
    >
      {files.length === 0 && (
        <div className="flex flex-col gap-4 items-center justify-center">
          <Typography color="rain_fog" fontSize="20" lineHeight={1}>
            <i className="far fa-file-pdf" />
          </Typography>
          <UploadButton
            allowedExtensions={['pdf']}
            onUpload={(uploadUuid) => associateFile.mutate(uploadUuid)}
            text="Add File"
            triggerProps={{
              variant: 'link',
              leftIcon: '',
              padding: '0',
            }}
          />
        </div>
      )}
      <div className="-mx-2">
        {visibleFiles.map((file) => (
          <div key={file.uuid}>
            <FileRow file={file} />
          </div>
        ))}
      </div>

      {files.length > 3 && (
        <div className="flex justify-center pt-1">
          <CabalButton
            variant="link"
            onClick={() => (limit === 3 ? setLimit(files.length) : setLimit(3))}
          >
            See all ({files.length})
          </CabalButton>
        </div>
      )}
    </Widget>
  )
}

export default Files
