import React, { useState } from 'react'

import { useMutation } from 'react-query'

import CabalButton from 'global/CabalButton'
import { TextArea } from 'global/Input'
import Modal from 'global/Modal'
import { ModalSectionWrapper } from 'global/Modal/styles'
import Typography from 'global/Typography'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'

interface ArchiveModalProps {
  onHide: () => void
  introRequestUuid: string
  name: string
  requestorName: string
}

const ArchiveModal = ({ onHide, introRequestUuid, name, requestorName }: ArchiveModalProps) => {
  const [note, setNote] = useState('')

  const { mutate: archiveIntroRequestMutate } = useMutation({
    mutationFn: () => callApi(api.archiveIntroRequestV2, introRequestUuid, note),
    onSuccess: () => {
      cabalToast({ style: 'success', content: 'Intro archived!' })
      onHide()
    },
    onError: () => {
      cabalToast({ style: 'error', content: 'Error archiving intro' })
    },
  })

  return (
    <Modal
      show
      onHide={onHide}
      header={`Archive Intro to ${name}`}
      centerActions={
        <div className="w-full">
          <CabalButton className="w-24" onClick={() => archiveIntroRequestMutate()}>
            Archive
          </CabalButton>
        </div>
      }
    >
      <div>
        <Typography fontSize="12" lineHeight="1.2" className="pb-2" component="p">
          {`Note to ${requestorName.split(' ')[0]} (optional)`}
        </Typography>
        <TextArea
          placeholder="Optional note"
          value={note}
          rows={4}
          onChange={(e) => setNote(e.target.value)}
          className="w-full"
        />
      </div>
    </Modal>
  )
}

export default ArchiveModal
