import React, { useState } from 'react'

import moment from 'moment'
import { useMutation, useQueryClient } from 'react-query'

import { EducationEntry } from 'containers/CandidateProfileModal/WorkHistory'
import { IEducation } from 'containers/GlobalUserProfile/types'
import CabalButton from 'global/CabalButton'
import Modal from 'global/Modal'
import { ModalSectionHeader } from 'global/Modal/styles'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { CandidateEducation } from 'utils/types'

interface EducationModalProps {
  onHide: () => void
  education?: CandidateEducation[]
  slug: string
}

const EducationModal = ({ onHide, education: initialEducation, slug }: EducationModalProps) => {
  const queryClient = useQueryClient()
  const [educationHistory, setEducationHistory] = useState<IEducation[]>(
    initialEducation?.map((edu) => ({
      ...edu,
      fieldOfStudy: edu.field_of_study,
      fromMonth: parseInt(edu.starts_at.month),
      fromYear: parseInt(edu.starts_at.year),
      toMonth: parseInt(edu.ends_at.month),
      toYear: parseInt(edu.ends_at.year),
    })) || [],
  )

  const updateEducation = useMutation<CandidateEducation, Error>(
    () =>
      callApi(api.updateEducation, {
        slug,
        updateData: educationHistory,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['globalPersonEducations', slug])
        onHide()
      },
      onError: () => {
        cabalToast({ style: 'error', content: 'Failed to update education' })
      },
    },
  )

  return (
    <Modal
      header="Education"
      show={true}
      onHide={onHide}
      dangerouslyBypassFocusLock={true}
      rightActions={
        <CabalButton
          type="button"
          variant="primary"
          onClick={() => updateEducation.mutate()}
          working={updateEducation.isLoading}
        >
          Save
        </CabalButton>
      }
    >
      <div className="flex justify-between items-center">
        <ModalSectionHeader>Education History</ModalSectionHeader>
        <CabalButton
          variant="link"
          type="button"
          onClick={() => {
            setEducationHistory([
              {
                school: '',
                degree: '',
                field: '',
                uuid: crypto.randomUUID(),
                fromMonth: null,
                fromYear: null,
                toMonth: null,
                toYear: null,
              },
              ...educationHistory,
            ])
          }}
        >
          + Add More
        </CabalButton>
      </div>
      {educationHistory.map((entry, i) => (
        <EducationEntry
          key={entry.uuid || i}
          entry={entry}
          onUpdate={(newEntry) => {
            const newEducationHistory = [...educationHistory]
            newEducationHistory[i] = {
              ...newEducationHistory[i],
              ...newEntry,
            }
            setEducationHistory(newEducationHistory)
          }}
          hasMultipleEntries={educationHistory.length > 1}
          onRemove={() => {
            setEducationHistory(educationHistory.filter((_, index) => index !== i))
          }}
          isLast={i === educationHistory.length - 1}
        />
      ))}
    </Modal>
  )
}

export default EducationModal
