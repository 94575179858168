import React, { useEffect } from 'react'

/**
 * CKEditorToolbarFix
 *
 * This component fixes the CKEditor toolbar visibility issue by forcing it to stay visible.
 * It's a temporary solution until a proper fix can be implemented through CKEditor's API.
 *
 * Usage:
 * <CKEditorToolbarFix />
 *
 * Note: Place this component within the same container as your CKEditor instance.
 */
const CKEditorToolbarFix: React.FC = () => {
  useEffect(() => {
    const forceShowToolbar = () => {
      const stickyPanel = document.querySelector('.ck-sticky-panel__content')
      const placeholder = document.querySelector('.ck-sticky-panel__placeholder')

      if (stickyPanel) {
        stickyPanel.setAttribute('style', 'position: static !important; width: 100% !important;')
      }

      if (placeholder) {
        placeholder.setAttribute('style', 'height: auto !important; display: block !important;')
      }
    }

    forceShowToolbar()
    const interval = setInterval(forceShowToolbar, 100)

    return () => clearInterval(interval)
  }, [])

  return null
}

export default CKEditorToolbarFix
