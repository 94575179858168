import { AxiosError } from 'axios'
import { AsyncReturnType, ValueOf } from 'type-fest'
import { v4 as uuidv4 } from 'uuid'
import * as Browser from 'webextension-polyfill'

import { API, extractGeneric } from 'utils/api'
import { getEnv, isProduction } from 'utils/env'

class BackgroundApiCaller {
  promiseResolvers: Record<string, any> = {}
  promiseRejecters: Record<string, any> = {}
  queuedAt: Record<string, number> = {}

  constructor() {
    this.startListening()
    this.callTimeout()
    setInterval(this.cleanup, 60000)
  }

  cleanup = () => {
    const now = new Date().getTime()
    Object.keys(this.queuedAt).forEach((uuid) => {
      if (now - this.queuedAt[uuid] > 30000) {
        delete this.promiseResolvers[uuid]
        delete this.promiseRejecters[uuid]
        delete this.queuedAt[uuid]
      }
    })
  }

  startListening = () => {
    browser.runtime.onMessage.addListener(this.handleMessage)
  }

  stopListening = () => {
    browser.runtime.onMessage.removeListener(this.handleMessage)
  }

  callTimeout = () => {
    const now = new Date().getTime()

    Object.entries(this.queuedAt).forEach(([uuid, queuedAt]) => {
      if (now - queuedAt > 10000) {
        this.promiseRejecters[uuid]?.({
          message: 'Timeout',
          code: 'timeout',
        } as AxiosError)
        delete this.promiseRejecters[uuid]
        delete this.promiseResolvers[uuid]
        delete this.queuedAt[uuid]
      }
    })
  }

  handleMessage = async (message: any, sender: browser.Runtime.MessageSender) => {
    if (sender.id !== browser.runtime.id) return

    switch (message.type) {
      case 'callExtApi':
        const { uuid, error, response } = message

        if (error) {
          this.promiseRejecters[uuid]?.(error)
        } else {
          this.promiseResolvers[uuid]?.(response)
        }
        break
    }
  }

  waitTillResponse = async (uuid: string) => {
    const promise = new Promise<any>((resolve, reject) => {
      this.promiseResolvers[uuid] = resolve
      this.promiseRejecters[uuid] = reject
      this.queuedAt[uuid] = new Date().getTime()
    })

    return promise
  }
}

export async function callExtApi<A extends ValueOf<API>>(api: A, ...args: Parameters<A>) {
  if (typeof api !== 'function') throw "`api` can't be string, please pass a function"

  if (getEnv('useBackgroundScriptForApiCalls')) {
    const uuid = uuidv4()
    const backgroundApiCaller = new BackgroundApiCaller()

    if (!isProduction()) {
      console.log(
        `%c👨‍💻->👾 callExtApi api.${api.name}`,
        'background: lightseagreen; color: white; padding: 3px;',
        args,
      )
    }

    Browser.runtime.sendMessage({
      action: 'callExtApi',
      uuid,
      apiName: api.name,
      args,
    })

    const response = await backgroundApiCaller.waitTillResponse(uuid)

    if (!isProduction()) {
      console.log(
        `%c👾->👨‍💻 callExtApi response api.${api.name}`,
        'background: green; color: white; padding: 3px;',
        response,
      )
    }

    backgroundApiCaller.stopListening()
    return response.data as extractGeneric<AsyncReturnType<A>>
  } else {
    const response = await api(...(args as Parameters<A>))
    return response.data as extractGeneric<AsyncReturnType<A>>
  }
}
