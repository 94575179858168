import React, { useState } from 'react'

import moment from 'moment'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'

import { SeeMore } from 'containers/CandidateProfile'
import ExperienceModal from 'containers/GlobalUserProfile/Modals/ExperienceModal'
import CabalButton from 'global/CabalButton'
import { useModal } from 'global/Modal'
import Typography from 'global/Typography'
import DataCardV2 from 'ui-components/DataCardV2'
import Widget from 'ui-components/Widget'

import api, { callApi } from 'utils/api'
import { CandidateWorkExperience } from 'utils/types'

const ExperienceRow = ({ work }: { work: CandidateWorkExperience }) => {
  const limit = 80
  const [isExpanded, setIsExpanded] = useState(!!work.summary ? work.summary.length < limit : false)
  const description = []
  const summary = isExpanded ? work.summary : work.summary ? `${work.summary.slice(0, limit)}` : ''

  if (work.company) {
    description.push(
      <div>
        <i className="far fa-building mr-1" /> {work.company}
      </div>,
    )
  }

  if (work.starts_at) {
    const startDate = moment.utc({
      year: work.starts_at.year,
      month: work.starts_at.month - 1, // moment months are 0-based
      day: work.starts_at.day,
    })

    let dateString = startDate.format('MMM YYYY')

    if (work.ends_at && work.ends_at.year && work.ends_at.month && work.ends_at.day) {
      // check if end_at exists and has a year
      const endDate = moment.utc({
        year: work.ends_at.year,
        month: work.ends_at.month - 1,
        day: work.ends_at.day,
      })
      dateString += ` - ${endDate.format('MMM YYYY')}`
    } else {
      dateString += ' - Present'
    }

    description.push(<div>{dateString}</div>)
  }

  return (
    <div className="flex flex-col">
      <DataCardV2
        avatar={<i className="far fa-briefcase" />}
        title={work.title}
        description={description}
        className="hover:bg-transparent dark:hover:bg-transparent"
      />
      <p className="ml-12 pl-1 leading-5 -mt-2.5 mb-2">
        <Typography color="rain_fog" fontSize="12" lineHeight={1}>
          {summary}
        </Typography>
        {work.summary && work.summary.length > limit && (
          <SeeMore
            className="ml-1 cursor-pointer"
            color="fog"
            fontSize="12"
            lineHeight={1}
            onClick={() => setIsExpanded(!isExpanded)}
          >
            {isExpanded ? '...see less' : '...see more'}
          </SeeMore>
        )}
      </p>
    </div>
  )
}

const Experience = ({ canEdit }: { canEdit: boolean }) => {
  const { slug } = useParams<{ slug: string }>()
  const { showModal } = useModal()
  const [limit, setLimit] = React.useState(3)
  const queryClient = useQueryClient()

  const { data: workExperiences = [] } = useQuery<CandidateWorkExperience[]>(
    ['globalPersonWorkExperiences', slug],
    () => callApi(api.getWorkExperiences, { slug }),
    {
      enabled: !!slug,
    },
  )

  const sortedWorkHistory = React.useMemo(
    () =>
      workExperiences.sort(
        (a, b) => new Date(b.start_at).getTime() - new Date(a.start_at).getTime(),
      ),
    [workExperiences],
  )

  const workHistory = React.useMemo(
    () => sortedWorkHistory.slice(0, limit),
    [sortedWorkHistory, limit],
  )

  const renderExperienceModal = (resolve: () => void) => (
    <ExperienceModal onHide={resolve} experience={workExperiences} slug={slug} />
  )

  if (sortedWorkHistory.length === 0 && !canEdit) {
    return null
  }

  return (
    <Widget
      title="Experience"
      className="group"
      cta={
        canEdit && (
          <div className="group-hover:flex hidden flex-row gap-8">
            <CabalButton
              variant="link"
              padding={'0'}
              leftIcon={<i className="far fa-edit" />}
              onClick={() => showModal(renderExperienceModal, 'exp_modal')}
            >
              Edit
            </CabalButton>
          </div>
        )
      }
    >
      {sortedWorkHistory.length === 0 && (
        <div className="flex flex-col gap-4 items-center justify-center">
          <Typography color="rain_fog" fontSize="20" lineHeight={1}>
            <i className="far fa-briefcase" />
          </Typography>
          <CabalButton
            padding={'0'}
            variant="link"
            onClick={() => showModal(renderExperienceModal, 'exp_modal')}
          >
            Add Work Experience
          </CabalButton>
        </div>
      )}
      {sortedWorkHistory.length > 0 && (
        <div className="-mx-2 flex flex-col gap-3">
          {workHistory.map((work) => (
            <ExperienceRow key={work.uuid} work={work} />
          ))}
        </div>
      )}

      {sortedWorkHistory.length > 3 && (
        <div className="flex justify-center pt-1">
          <CabalButton
            variant="link"
            onClick={() => (limit === 3 ? setLimit(sortedWorkHistory.length) : setLimit(3))}
          >
            See all ({sortedWorkHistory.length})
          </CabalButton>
        </div>
      )}
    </Widget>
  )
}

export default Experience
