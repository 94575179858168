import React, { useState } from 'react'

import { SelectWrapper } from 'containers/CandidateProfileModal/PersonalInfo'
import { IAboutOptions } from 'containers/GlobalUserProfile/Modals/PreferencesModal'
import {
  COMPANY_SIZE_WITH_OPEN_TO_ALL_OPTIONS,
  LEVEL_OPTIONS,
  OFFICE_WITH_OPEN_TO_ALL_OPTIONS,
  ROLE_FUNCTION_OPTIONS,
  STAGE_OPTIONS,
  STAGE_WITH_OPEN_TO_ALL_OPTIONS,
} from 'containers/ListIndexView/constants'
import { MultiSelect, Select, TextArea, TextInput } from 'global/Input'
import CityInput from 'global/Input/CityInput'
import { ModalInputLabel, ModalInputWrapper } from 'global/Modal/styles'

const EditDetails = ({
  options,
  setPrefOptions,
}: {
  options: IAboutOptions
  setPrefOptions: (options: IAboutOptions) => void
}) => {
  return (
    <>
      <ModalInputWrapper className="py-2 text-left">
        <ModalInputLabel>Function</ModalInputLabel>
        <MultiSelect
          placeholder="Primary job function(s)"
          options={ROLE_FUNCTION_OPTIONS}
          value={options.function_preference}
          onChange={(e) => setPrefOptions({ ...options, function_preference: e })}
        />
      </ModalInputWrapper>
      <ModalInputWrapper className="py-2">
        <ModalInputLabel>Level</ModalInputLabel>
        <MultiSelect
          placeholder="Experience level"
          value={options.level_preference}
          onChange={(e) => setPrefOptions({ ...options, level_preference: e })}
          options={LEVEL_OPTIONS}
        />
      </ModalInputWrapper>

      <ModalInputWrapper className="py-2 text-left">
        <ModalInputLabel>Stage</ModalInputLabel>
        <MultiSelect
          placeholder="Company stage experience"
          value={options.stage_preference}
          onChange={(e) => setPrefOptions({ ...options, stage_preference: e })}
          options={STAGE_WITH_OPEN_TO_ALL_OPTIONS}
        />
      </ModalInputWrapper>

      <ModalInputWrapper className="py-2">
        <ModalInputLabel>Size</ModalInputLabel>

        <MultiSelect
          placeholder="Preferred company size"
          options={COMPANY_SIZE_WITH_OPEN_TO_ALL_OPTIONS}
          value={options.ideal_company_size}
          onChange={(companySize) =>
            setPrefOptions({ ...options, ideal_company_size: companySize })
          }
        />
      </ModalInputWrapper>

      <ModalInputWrapper className="py-2 text-left">
        <ModalInputLabel>Location</ModalInputLabel>
        <CityInput
          onChange={(opt) => {
            setPrefOptions({ ...options, location: opt?.value })
          }}
          value={{ value: options.location, label: options.location }}
        />
      </ModalInputWrapper>

      <ModalInputWrapper className="py-2">
        <ModalInputLabel>Office</ModalInputLabel>

        <MultiSelect
          placeholder="Preferred location(s)"
          options={OFFICE_WITH_OPEN_TO_ALL_OPTIONS}
          value={options.office_preference}
          onChange={(office_preference) =>
            setPrefOptions({ ...options, office_preference: office_preference })
          }
        />
      </ModalInputWrapper>

      <ModalInputWrapper className="py-2">
        <ModalInputLabel>Compensation</ModalInputLabel>
        <TextInput
          value={options.minimum_salary}
          placeholder="Compensation expectation"
          onChange={(e) => setPrefOptions({ ...options, minimum_salary: e.target.value })}
        />
      </ModalInputWrapper>

      <ModalInputWrapper className="py-2">
        <ModalInputLabel>Work Status</ModalInputLabel>
        <SelectWrapper>
          <Select
            placeholder="Work Status"
            options={[
              { value: 'legal_to_work_in_us', label: 'Legal to Work in US' },
              { value: 'visa_requirement', label: 'Visa Requirement' },
            ]}
            value={options.work_status}
            onChange={(workStatus) =>
              setPrefOptions({ ...options, work_status: workStatus || undefined })
            }
          />
        </SelectWrapper>
      </ModalInputWrapper>

      <div className="mb-4">
        <ModalInputWrapper className="py-2">
          <ModalInputLabel>What are you looking for?</ModalInputLabel>
          <TextArea
            placeholder="What are you looking for?"
            value={options.what_youre_looking_for}
            onChange={(e) => setPrefOptions({ ...options, what_youre_looking_for: e.target.value })}
            rows={6}
          />
        </ModalInputWrapper>
      </div>
    </>
  )
}

export default EditDetails
