import React, { useState } from 'react'

import moment from 'moment'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'

import { SeeMore } from 'containers/CandidateProfile'
import CabalButton from 'global/CabalButton'
import { useModal } from 'global/Modal'
import Typography from 'global/Typography'
import DataCardV2 from 'ui-components/DataCardV2'
import Widget from 'ui-components/Widget'

import api, { callApi } from 'utils/api'
import { CandidateEducation } from 'utils/types'

import EducationModal from './Modals/EducationModal'

const EducationRow = ({ education }: { education: CandidateEducation }) => {
  const limit = 80
  const [isExpanded, setIsExpanded] = useState(
    !!education.summary ? education.summary.length < limit : false,
  )
  const description = []
  const field = isExpanded
    ? education.summary
    : education.summary
    ? `${education.summary.slice(0, limit)}`
    : ''

  if (education.field_of_study) {
    description.push(
      <div>
        <i className="far fa-graduation-cap mr-1" /> {education.field_of_study}
      </div>,
    )
  }

  if (education.starts_at) {
    const startDate = moment.utc({
      year: education.starts_at.year,
      month: education.starts_at.month - 1, // moment months are 0-based
      day: education.starts_at.day,
    })

    let dateString = startDate.format('MMM YYYY')

    if (
      education.ends_at &&
      education.ends_at.year &&
      education.ends_at.month &&
      education.ends_at.day
    ) {
      // check if end_at exists and has a year
      const endDate = moment.utc({
        year: education.ends_at.year,
        month: education.ends_at.month - 1,
        day: education.ends_at.day,
      })
      dateString += ` - ${endDate.format('MMM YYYY')}`
    } else {
      dateString += ' - Present'
    }

    description.push(<div>{dateString}</div>)
  }

  return (
    <div className="flex flex-col">
      <DataCardV2
        avatar={<i className="far fa-university" />}
        title={education.school}
        description={description}
        className="hover:bg-transparent dark:hover:bg-transparent"
      />
      {field && (
        <p className="ml-12 pl-1 leading-5 -mt-2.5 mb-2">
          <Typography color="rain_fog" fontSize="12" lineHeight={1}>
            {field}
          </Typography>
          {education.summary && education.summary.length > limit && (
            <SeeMore
              className="ml-1 cursor-pointer"
              color="fog"
              fontSize="12"
              lineHeight={1}
              onClick={() => setIsExpanded(!isExpanded)}
            >
              {isExpanded ? '...see less' : '...see more'}
            </SeeMore>
          )}
        </p>
      )}
    </div>
  )
}

const Education = ({ canEdit }: { canEdit: boolean }) => {
  const { slug } = useParams()
  const { showModal } = useModal()
  const [limit, setLimit] = React.useState(3)

  const { data: educations = [] } = useQuery<CandidateEducation[]>(
    ['globalPersonEducations', slug],
    () => callApi(api.getEducations, { slug }),
    {
      enabled: !!slug,
    },
  )

  const sortedEducationHistory = React.useMemo(
    () =>
      educations.sort((a, b) => new Date(b.start_at).getTime() - new Date(a.start_at).getTime()),
    [educations],
  )

  const educationHistory = React.useMemo(
    () => sortedEducationHistory.slice(0, limit),
    [sortedEducationHistory, limit],
  )

  const renderEducationModal = (resolve: () => void) => (
    <EducationModal onHide={resolve} education={educations} slug={slug} />
  )

  if (sortedEducationHistory.length === 0 && !canEdit) {
    return null
  }

  return (
    <Widget
      title="Education"
      className="group"
      cta={
        canEdit && (
          <div className="group-hover:flex hidden flex-row gap-8">
            <CabalButton
              variant="link"
              padding={'0'}
              leftIcon={<i className="far fa-edit" />}
              onClick={() => showModal(renderEducationModal, 'education_modal')}
            >
              Edit
            </CabalButton>
          </div>
        )
      }
    >
      {sortedEducationHistory.length === 0 && (
        <div className="flex flex-col gap-4 items-center justify-center">
          <Typography color="rain_fog" fontSize="20" lineHeight={1}>
            <i className="far fa-graduation-cap" />
          </Typography>
          <CabalButton
            variant="link"
            onClick={() => showModal(renderEducationModal, 'education_modal')}
          >
            Add Education
          </CabalButton>
        </div>
      )}

      {sortedEducationHistory.length > 0 && (
        <div className="-mx-2 flex flex-col gap-3">
          {educationHistory.map((education) => (
            <EducationRow key={education.uuid} education={education} />
          ))}
        </div>
      )}
      {sortedEducationHistory.length > 3 && (
        <div className="flex justify-center pt-1">
          <CabalButton
            variant="link"
            onClick={() => (limit === 3 ? setLimit(sortedEducationHistory.length) : setLimit(3))}
          >
            See all ({sortedEducationHistory.length})
          </CabalButton>
        </div>
      )}
    </Widget>
  )
}

export default Education
