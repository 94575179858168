import React, { useState } from 'react'

import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import { LEVEL_MAP, ROLE_FUNCTION_MAP } from 'containers/ListIndexView/constants'
import CabalButton from 'global/CabalButton'
import { useModal } from 'global/Modal'
import Tooltip from 'global/Tooltip'
import Typography from 'global/Typography'
import Widget from 'ui-components/Widget'

import api, { callApi } from 'utils/api'
import { CandidateProfileAbout } from 'utils/types'

import AboutModal from './Modals/AboutModal'

export const SeeMore = styled(Typography)`
  &:hover {
    color: ${({ theme }) => theme.colors.purple};
  }
`

const AboutTooltip = ({ label, icon }: { label: string; icon: string }) => (
  <Tooltip label={label} placement="left">
    <Typography fontSize="14" lineHeight={1.2}>
      <i className={`far fa-${icon} fa-fw`} />
    </Typography>
  </Tooltip>
)

interface AboutProps {
  canEdit: boolean
}

const About = ({ canEdit }: AboutProps) => {
  const { slug } = useParams<{ slug: string }>()
  const { showModal } = useModal()
  const { data: profile } = useQuery<CandidateProfileAbout>(
    ['globalPersonAbout', slug],
    () => callApi(api.getGlobalPersonAbout, { slug }),
    {
      enabled: !!slug,
    },
  )

  const description = profile?.about
  const descriptionLength = description?.length || 0
  const [isExpanded, setIsExpanded] = useState(descriptionLength < 165 || false)
  const shownDescription = isExpanded ? description : description?.slice(0, 165)

  const renderAboutModal = (resolve: () => void) => (
    <AboutModal
      onHide={resolve}
      slug={slug}
      options={{
        functions: profile?.functions || [],
        location: profile?.location || '',
        level: profile?.level || '',
        about: profile?.about || '',
      }}
    />
  )

  return (
    <>
      <Widget
        title="About"
        className="group"
        cta={
          canEdit && (
            <div className="group-hover:flex hidden flex-row gap-8">
              <CabalButton
                variant="link"
                padding={'0'}
                leftIcon={<i className="far fa-edit" />}
                onClick={() => showModal(renderAboutModal, 'about-modal')}
              >
                Edit
              </CabalButton>
            </div>
          )
        }
      >
        <div className="flex flex-col gap-6">
          {description && (
            <p className="leading-5">
              <Typography fontSize="12" lineHeight={1.2}>
                {shownDescription}
              </Typography>
              {description.length > 165 && (
                <SeeMore
                  className="ml-1 cursor-pointer"
                  // color="fog"
                  fontSize="12"
                  fontWeight={600}
                  lineHeight={1.2}
                  onClick={() => setIsExpanded(!isExpanded)}
                >
                  {isExpanded ? '...see less' : '...see more'}
                </SeeMore>
              )}
            </p>
          )}

          <div className="flex flex-col gap-3">
            <p className="leading-5 flex gap-2">
              <AboutTooltip label="Function" icon="briefcase" />
              {profile?.functions && profile.functions.length > 0 ? (
                <Typography fontSize="12" lineHeight={1.2}>
                  {profile.functions
                    .map((func) => ROLE_FUNCTION_MAP[func as keyof typeof ROLE_FUNCTION_MAP])
                    .join(', ')}
                </Typography>
              ) : (
                <Typography fontSize="12" lineHeight={1.2}>
                  -
                </Typography>
              )}
            </p>

            <p className="leading-5 flex gap-2">
              <AboutTooltip label="Level" icon="user" />
              <Typography fontSize="12" lineHeight={1.2}>
                {profile?.level ? LEVEL_MAP[profile?.level] : '-'}
              </Typography>
            </p>

            <p className="leading-5 flex gap-2">
              <AboutTooltip label="Location" icon="location-dot" />
              <Typography fontSize="12" lineHeight={1.2}>
                {profile?.location || '-'}
              </Typography>
            </p>
          </div>
        </div>
      </Widget>
    </>
  )
}

export default About
