import React from 'react'

import { GlobalPerson } from 'app/javascript/hooks/useGlobalPerson'
import { useMutation, useQueryClient } from 'react-query'

import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import { TextInput, UploadButton } from 'global/Input'
import Modal from 'global/Modal'
import { ModalInputLabel, ModalInputWrapper, ModalSectionWrapper } from 'global/Modal/styles'
import Typography from 'global/Typography'

import api, { callApi } from 'utils/api'
import { PersonBlueprint } from 'utils/types'

interface HeaderModalProps {
  info: PersonBlueprint
  onHide: () => void
}

const HeaderModal: React.FC<HeaderModalProps> = ({ info, onHide }) => {
  const [infoData, setInfoData] = React.useState<PersonBlueprint>(() => info)
  const queryClient = useQueryClient()

  const updatePerson = useMutation(
    (updateData: Partial<PersonBlueprint>) =>
      callApi(api.updateGlobalPersonV2, {
        slug: info.uuid,
        updateData,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['globalPerson', info.uuid])
        onHide()
      },
    },
  )

  return (
    <Modal
      show={true}
      header="User Info"
      onHide={onHide}
      dangerouslyBypassFocusLock={true}
      rightActions={<CabalButton onClick={() => updatePerson.mutate(infoData)}>Save</CabalButton>}
    >
      <ModalSectionWrapper>
        <ModalInputWrapper className="py-1">
          <ModalInputLabel>Photo</ModalInputLabel>
          <div className="flex">
            <UploadButton
              allowedExtensions={['png', 'jpg', 'jpeg']}
              onUpload={(uuid) => setInfoData({ ...infoData, image_url: `/api/uploads/${uuid}` })}
              showPencil={true}
              text=""
              triggerProps={{
                variant: 'tertiary',
                leftIcon: (
                  <Typography className="flex items-start justify-start">
                    <Avatar src={infoData.image_url} size={'32'} name={infoData.first_name} />
                    <p className="pt-4 pl-3">Edit</p>
                  </Typography>
                ),
                padding: '0',
              }}
            />
          </div>
        </ModalInputWrapper>

        <ModalInputWrapper className="py-1">
          <ModalInputLabel>First Name</ModalInputLabel>

          <TextInput
            placeholder="Enter first name"
            autoFocus={true}
            value={infoData.first_name}
            onChange={(e) => setInfoData({ ...infoData, first_name: e.target.value })}
          />
        </ModalInputWrapper>

        <ModalInputWrapper className="py-1">
          <ModalInputLabel>Last Name</ModalInputLabel>
          <TextInput
            placeholder="Enter last name"
            autoFocus={true}
            value={infoData.last_name}
            onChange={(e) => setInfoData({ ...infoData, last_name: e.target.value })}
          />
        </ModalInputWrapper>

        <ModalInputWrapper className="py-1">
          <ModalInputLabel>Headline</ModalInputLabel>
          <TextInput
            placeholder="Headline"
            autoFocus={true}
            value={infoData.headline}
            onChange={(e) => setInfoData({ ...infoData, headline: e.target.value })}
          />
        </ModalInputWrapper>

        <ModalInputWrapper className="py-1">
          <ModalInputLabel>LinkedIn URL</ModalInputLabel>
          <TextInput
            placeholder="LinkedIn URL"
            autoFocus={true}
            value={infoData.linkedin_url}
            onChange={(e) => setInfoData({ ...infoData, linkedin_url: e.target.value })}
          />
        </ModalInputWrapper>
      </ModalSectionWrapper>
    </Modal>
  )
}

export default HeaderModal
