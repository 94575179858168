import React, { createContext, useContext, useMemo, useState } from 'react'

import moment from 'moment'
import { useInfiniteQuery } from 'react-query'
import { ArrayParam, BooleanParam, StringParam } from 'use-query-params'

import SalesCompanyList from 'containers/ListIndexView/SalesList/SalesCompanyList'
import SalesPeopleList from 'containers/ListIndexView/SalesList/SalesPeopleList'
import {
  ISalesCompany,
  ISalesPeople,
  SalesFilterTypes,
  SalesListContextProps,
} from 'containers/ListIndexView/SalesList/type'
import { useAccessControl } from 'global/AccessControl'
import CabalButton from 'global/CabalButton'
import PageWrapper from 'global/PageWrapper'
import { useTeamSlug } from 'store/hooks'

import api, { callApi } from 'utils/api'
import companyList from 'utils/api/companyList'
import { useQueryParamsWithSettings } from 'utils/hooks/useQueryParamsWithSettings'
import { CompanyListBlueprint } from 'utils/types'

interface Props {
  companyListData: CompanyListBlueprint
  refetchCompanyList: () => void
}

export const SalesListContext = createContext<SalesListContextProps | undefined>(undefined)

export const useSalesListContext = () => {
  const context = useContext(SalesListContext)
  if (context === undefined) {
    throw new Error('useSalesListContext must be used within a SalesListProvider')
  }
  return context
}

const SalesList = ({ companyListData, refetchCompanyList }: Props) => {
  const teamSlug = useTeamSlug()
  const { isAdminOrEmployee } = useAccessControl(teamSlug)

  // TODO get rid of this weird setting
  const listTypeData = window.LIST_TYPES[companyListData.list_type]

  const queryParamConfigMap = useMemo(() => {
    return {
      search: ArrayParam,
      member_view: BooleanParam,
      sort: StringParam,
      advisor_uuids: ArrayParam,
      group_uuids: ArrayParam,
    }
  }, [])

  const [filters, setFilters_] = useQueryParamsWithSettings(queryParamConfigMap)

  const setFilters = (updatedFilters: SalesFilterTypes) => {
    setFilters_(updatedFilters)
  }

  const {
    isLoading: salesListIsLoading,
    data,
    refetch: refetchSalesList,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    dataUpdatedAt: companyDataUpdatedAt,
  } = useInfiniteQuery(
    [
      teamSlug,
      'opportunityData',
      companyListData?.uuid,
      companyListData?.status?.stale,
      companyListData?.item_count,
      filters,
    ],
    ({ pageParam: page = 1 }) =>
      callApi(
        api.getCompanies,
        teamSlug, // team slug
        page, // page
        {
          ...filters,
          sources: [companyListData?.uuid],
        }, // filters
      ),
    {
      cacheTime: 0,
      refetchInterval: (data) => {
        if (listTypeData?.es || companyListData?.default_type_identifier) {
          const results = data?.pages?.flatMap((page) => {
            return page.company_ids?.map((id) => page.company_records[id]) ?? []
          })
          const isEmptyAndRecentlyIndexed =
            (results ?? []).length === 0 &&
            (!companyListData?.status?.initial_indexing_completed_at ||
              moment().diff(
                moment(companyListData.status.initial_indexing_completed_at),
                'minutes',
              ) < 5)

          if (isEmptyAndRecentlyIndexed) {
            return 1500
          }
        }

        return false
      },
      getNextPageParam: (lastPage) => lastPage.pagination.next_page,
      getPreviousPageParam: (firstPage) => firstPage.pagination.prev_page,
      // onSettled: () => setShowResultsLoading(false),
      keepPreviousData: true,
      enabled: true,
    },
  )

  const salesCompanies: ISalesCompany[] | ISalesPeople[] = useMemo(() => {
    let companiesWithResults: any = []
    // get all the companies with results
    if (data?.pages?.[0]?.sources?.includes(companyListData?.uuid) || !companyListData?.uuid) {
      companiesWithResults = data?.pages?.flatMap((page) => {
        return page.company_ids.map((id) => page.company_records[id])
      })
    }
    return companiesWithResults
  }, [data?.pages, companyListData?.uuid])

  const adminView = !!isAdminOrEmployee && !!companyListData.owned && !filters.member_view
  const memberView = !isAdminOrEmployee || !!filters.member_view
  const salesListEmpty = salesCompanies.length === 0
  const sharedListView = !companyListData.owned

  const contextValue = useMemo(() => {
    return {
      companyListData,
      refetchCompanyList,
      refetchSalesList,
      adminView,
      salesCompanies,
      salesPeople: salesCompanies,
    }
  }, [
    companyListData,
    refetchCompanyList,
    adminView,
    salesCompanies,
    refetchSalesList,
    // view,
    // setView,
  ])

  const isCompanyList = companyListData.list_type === 'companies'
  const ispeopleList = companyListData.list_type === 'people'

  return (
    <SalesListContext.Provider value={contextValue}>
      <PageWrapper title={companyListData.name}>
        {isCompanyList && (
          <SalesCompanyList
            listLoading={salesListIsLoading}
            memberView={memberView}
            adminView={adminView}
            salesListEmpty={salesListEmpty}
            sharedListView={sharedListView}
            filters={filters}
            setFilters={setFilters}
          />
        )}

        {ispeopleList && (
          <SalesPeopleList
            listLoading={salesListIsLoading}
            memberView={memberView}
            adminView={adminView}
            salesListEmpty={salesListEmpty}
            sharedListView={sharedListView}
            filters={filters}
            setFilters={setFilters}
          />
        )}
        {hasNextPage && (
          <div className="flex justify-center	my-6">
            <CabalButton onClick={() => fetchNextPage()} disabled={isFetchingNextPage}>
              Load More
            </CabalButton>
          </div>
        )}
      </PageWrapper>
    </SalesListContext.Provider>
  )
}

export default SalesList
