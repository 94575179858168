import React, { useState } from 'react'

import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import {
  LEVEL_MAP,
  OFFICE_WITH_OPEN_TO_ALL_MAP,
  ROLE_FUNCTION_MAP,
  STAGE_WITH_OPEN_TO_ALL_MAP,
} from 'containers/ListIndexView/constants'
import CabalButton from 'global/CabalButton'
import { useModal } from 'global/Modal'
import Tooltip from 'global/Tooltip'
import Typography from 'global/Typography'
import Widget from 'ui-components/Widget'

import api, { callApi } from 'utils/api'
import { CandidateProfile } from 'utils/types'

import PreferencesModal from './Modals/PreferencesModal'

export const SeeMore = styled(Typography)`
  &:hover {
    color: ${({ theme }) => theme.colors.purple};
  }
`

const PreferenceTooltip = ({ label, icon }: { label: string; icon: string }) => (
  <Tooltip label={label} placement="left">
    <Typography fontSize="14" lineHeight={1.2}>
      <i className={`far fa-${icon} fa-fw`} />
    </Typography>
  </Tooltip>
)

interface AboutProps {
  canEdit: boolean
  hasCandidateProfile: boolean
}

const Preferences = ({ canEdit, hasCandidateProfile }: AboutProps) => {
  const { slug } = useParams<{ slug: string }>()
  const { showModal } = useModal()
  const { data: profile } = useQuery<CandidateProfile>(
    ['globalPersonCandidateProfile', slug],
    () => callApi(api.getCandidateProfile, { slug }),
    {
      enabled: !!slug,
    },
  )

  const description = profile?.what_youre_looking_for
  const descriptionLength = description?.length || 0
  const [isExpanded, setIsExpanded] = useState(descriptionLength < 165 || false)
  const shownDescription = isExpanded ? description : description?.slice(0, 165)

  const renderPreferencesModal = (resolve: () => void, tab?: 'visibility' | 'edit') => (
    <PreferencesModal
      onHide={resolve}
      options={{
        function_preference: profile?.function_preference || [],
        location: profile?.location || '',
        level_preference: profile?.level_preference || [],
        funding_stage_experience: profile?.funding_stage_experience || [],
        what_youre_looking_for: profile?.what_youre_looking_for || '',
        work_status: profile?.work_status || '',
        ideal_company_size: profile?.ideal_company_size || [],
        minimum_salary: profile?.minimum_salary || '',
        office_preference: profile?.office_preference || [],
        stage_preference: profile?.stage_preference || [],
      }}
      personUuid={slug}
      defaultTab={tab}
    />
  )

  return (
    <>
      <Widget
        title="Candidate Preferences"
        className="group"
        cta={
          canEdit && (
            <div className="group-hover:flex hidden flex-row gap-8">
              <CabalButton
                variant="link"
                padding={'0'}
                leftIcon={<i className="far fa-edit" />}
                onClick={() =>
                  showModal(
                    (resolve) => renderPreferencesModal(resolve, 'edit'),
                    'preferences-modal',
                  )
                }
              >
                Edit
              </CabalButton>
            </div>
          )
        }
      >
        {!hasCandidateProfile && (
          <div className="flex flex-col gap-4">
            <Typography fontSize="14" component="div" className="mb-1">
              Enable candidate preferences to share your job search details. You always control who
              can see this information.
            </Typography>
            <CabalButton
              variant="primary"
              onClick={() => showModal(renderPreferencesModal, 'preferences-modal')}
            >
              Enable
            </CabalButton>
          </div>
        )}
        {hasCandidateProfile && (
          <div className="flex flex-col gap-6">
            {description && (
              <p className="leading-5">
                <Typography fontSize="12" lineHeight={1.2}>
                  {shownDescription}
                </Typography>
                {description.length > 165 && (
                  <SeeMore
                    className="ml-1 cursor-pointer"
                    fontSize="12"
                    fontWeight={600}
                    lineHeight={1.2}
                    onClick={() => setIsExpanded(!isExpanded)}
                  >
                    {isExpanded ? '...see less' : '...see more'}
                  </SeeMore>
                )}
              </p>
            )}
            <div className="flex flex-col gap-3">
              <p className="leading-5 flex gap-2">
                <PreferenceTooltip label="Functions" icon="briefcase" />
                {profile?.function_preference && profile.function_preference.length > 0 ? (
                  <Typography fontSize="12" lineHeight={1.2}>
                    {profile.function_preference
                      .map((func) => ROLE_FUNCTION_MAP[func as keyof typeof ROLE_FUNCTION_MAP])
                      .join(', ')}
                  </Typography>
                ) : (
                  <Typography fontSize="12" lineHeight={1.2}>
                    -
                  </Typography>
                )}
              </p>

              <p className="leading-5 flex gap-2">
                <PreferenceTooltip label="Level" icon="user" />
                {profile?.level_preference && profile.level_preference.length > 0 ? (
                  <Typography fontSize="12" lineHeight={1.2}>
                    {profile.level_preference
                      .map((level) => LEVEL_MAP[level as keyof typeof LEVEL_MAP])
                      .join(', ')}
                  </Typography>
                ) : (
                  <Typography fontSize="12" lineHeight={1.2}>
                    -
                  </Typography>
                )}
              </p>

              <p className="leading-5 flex gap-2">
                <PreferenceTooltip label="Stage" icon="layer-group" />
                {profile?.stage_preference && profile.stage_preference.length > 0 ? (
                  <Typography fontSize="12" lineHeight={1.2}>
                    {profile.stage_preference
                      .map(
                        (stage) =>
                          STAGE_WITH_OPEN_TO_ALL_MAP[
                            stage as keyof typeof STAGE_WITH_OPEN_TO_ALL_MAP
                          ],
                      )
                      .join(', ')}
                  </Typography>
                ) : (
                  <Typography fontSize="12" lineHeight={1.2}>
                    -
                  </Typography>
                )}
              </p>

              <p className="leading-5 flex gap-2">
                <PreferenceTooltip label="Office" icon="building" />
                {profile?.office_preference && profile.office_preference.length > 0 ? (
                  <Typography fontSize="12" lineHeight={1.2}>
                    {profile.office_preference
                      .map(
                        (office) =>
                          OFFICE_WITH_OPEN_TO_ALL_MAP[
                            office as keyof typeof OFFICE_WITH_OPEN_TO_ALL_MAP
                          ],
                      )
                      .join(', ')}
                  </Typography>
                ) : (
                  <Typography fontSize="12" lineHeight={1.2}>
                    -
                  </Typography>
                )}
              </p>

              <p className="leading-5 flex gap-2">
                <PreferenceTooltip label="Company Size" icon="users" />
                <Typography fontSize="12" lineHeight={1.2}>
                  {profile?.ideal_company_size && profile.ideal_company_size.length > 0
                    ? profile.ideal_company_size
                        .map((size) => (size === 'open_to_all' ? 'Open to all' : size))
                        .join(', ')
                    : '-'}
                </Typography>
              </p>

              <p className="leading-5 flex gap-2">
                <PreferenceTooltip label="Location" icon="location-dot" />
                <Typography fontSize="12" lineHeight={1.2}>
                  {profile?.location || '-'}
                </Typography>
              </p>

              <p className="leading-5 flex gap-2">
                <PreferenceTooltip label="Compensation" icon="money-bill" />
                <Typography fontSize="12" lineHeight={1.2}>
                  {profile?.minimum_salary || '-'}
                </Typography>
              </p>

              <p className="leading-5 flex gap-2">
                <PreferenceTooltip label="Work Status" icon="passport" />
                <Typography fontSize="12" lineHeight={1.2}>
                  {profile?.work_status === 'legal_to_work_in_us'
                    ? 'Legal to Work in US'
                    : profile?.work_status === 'visa_requirement'
                    ? 'Visa Requirement'
                    : '-'}
                </Typography>
              </p>
            </div>
          </div>
        )}
      </Widget>
    </>
  )
}

export default Preferences
