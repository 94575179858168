import React from 'react'

import styled from 'styled-components'

import { AdvisorModel } from 'utils/types'

import Avatar from '../../../components/Avatar'
import { Button } from '../../../components/Button'
import Typography from '../../../components/Typography'

interface IProps {
  advisor: AdvisorModel
  onSelect: () => void
}

const Card = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  background: white;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  width: 100%;

  &:hover {
    background: #f9fafb;
    border-color: #d1d5db;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-left: 12px;
  flex: 1;
`

const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const StyledButton = styled(Button)`
  padding: 0;
  color: #6366f1;
  background: none;

  &:hover {
    color: #4f46e5;
  }
`

const AdvisorInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`

const AdvisorRow: React.FC<IProps> = ({ advisor, onSelect }) => {
  return (
    <Card
      onClick={() => {
        onSelect(advisor.uuid)
      }}
    >
      <Avatar name={advisor.first_name} src={advisor.avatar_url} size="32px" />
      <Content>
        <Typography weight="medium">
          {advisor.first_name} {advisor.last_name}
        </Typography>
        <AdvisorInfo>
          <Typography color="fog" size="sm">
            {advisor.title}
            {advisor.company_name && <span> at {advisor.company_name}</span>}
          </Typography>
        </AdvisorInfo>
      </Content>
      <ActionContainer>
        <StyledButton
          variant="tertiary"
          onClick={() => {
            onSelect(advisor.uuid)
          }}
        >
          <Typography size="sm">
            Select <i className="far fa-chevron-right" style={{ marginLeft: '4px' }} />
          </Typography>
        </StyledButton>
      </ActionContainer>
    </Card>
  )
}

export default AdvisorRow
