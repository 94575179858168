import React, { useState } from 'react'

import pluralize from 'pluralize'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'

import { useSalesListContext } from 'containers/ListIndexView/SalesList'
import { ISalesCompany, ISalesPeople } from 'containers/ListIndexView/SalesList/type'
import AnimatedVoteCTA from 'global/AnimatedVoteCTA'
import Avatar from 'global/Avatar'
import Loading from 'global/Loading'
import Typography from 'global/Typography'
import { useTeam, useTeamSlug } from 'store/hooks'
import DataCardV2 from 'ui-components/DataCardV2'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { cn } from 'utils/styles'
import { VoteType } from 'utils/types'

const SalesCompany = ({ person }: { person: ISalesPeople }) => {
  const history = useHistory()
  const teamSlug = useTeamSlug()
  const { team } = useTeam(teamSlug)
  const { companyListData } = useSalesListContext()
  const [vote, setVote] = useState<VoteType>(person.voted ? 1 : 0)

  const { mutate: sendVote, isLoading: sendingVote } = useMutation(
    ['createVote'],
    (vote: 1 | -1 | 0) =>
      callApi(api.createVote, {
        team_slug: teamSlug,
        votable_type: 'GlobalPerson',
        list_uuid: companyListData.uuid,
        votable_id: person.global_person_uuid,
        vote,
      }),
    {
      onSuccess: (_, variables) => {
        setVote(variables)
        if (variables == 1) {
          cabalToast({
            content: `Your offer to introduce ${person.name} has been logged. ${team?.name} will follow up with you via email. `,
            style: 'success',
          })
        }
      },
    },
  )
  return (
    <DataCardV2
      key={person.uuid}
      title={person.name}
      description={person.description}
      avatar={<Avatar src={person.avatar_url} name={person.name} size={'32'} />}
      titleIcon={
        person.profile_url && (
          <Typography
            color="fog"
            fontSize="14"
            lineHeight={1}
            onClick={(e) => {
              e.stopPropagation()
              window.open(person.profile_url, '_blank')
            }}
          >
            <i className="fab fa-linkedin" />
          </Typography>
        )
      }
      cta={
        <AnimatedVoteCTA
          selected={vote === 1}
          selectedText="Offered"
          unselectedText="Offer intro"
          onClick={() => sendVote(vote === 0 ? 1 : 0)}
        />
      }
      className={cn(
        'border-b-[1px] dark:border-[#2d3748] border-[#E3E5E8] rounded-none hover:rounded',
      )}
    />
  )
}

const SalesPeopleMemberView = () => {
  const { salesPeople } = useSalesListContext()
  return (
    <div>
      {salesPeople.map((salesPerson) => (
        <SalesCompany key={salesPerson.uuid} person={salesPerson} />
      ))}
    </div>
  )
}

export default SalesPeopleMemberView
