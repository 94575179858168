import React, { useState } from 'react'

import { Field, FieldProps, Form, Formik } from 'formik'
import Cookies from 'js-cookie'
import { useMutation } from 'react-query'
import { object, string } from 'yup'

import { useCandidateProfile } from 'containers/CandidateProfileModal'
import {
  LINKED_IN_PROFILE_URL_REGEX,
  getLinkedInSlug,
} from 'containers/CandidateProfileModal/linkedInAutoPrefill'
import CabalButton from 'global/CabalButton'
import { Select, SelectField, TextInputField } from 'global/Input'
import { ModalInputLabel } from 'global/Modal/styles'
import Typography from 'global/Typography'
import { useCurrentUser } from 'store/hooks'
import InfoTooltip from 'ui-components/InfoTooltip'

import api, { callApi } from 'utils/api'
import { CurrentUserProfile, Team } from 'utils/types'

import Header from './Header'
import { Card, ContinueWrapper, InputWrapper } from './styles'

interface Props {
  onContinue: () => void
  linkedinRequired?: boolean
  onBack?: () => void
  candidateOnboarding?: boolean
  team?: Team
}

const ProfileSetup: React.FC<Props> = ({
  onContinue,
  linkedinRequired,
  onBack,
  candidateOnboarding,
  team,
}) => {
  const { reloadUser, user } = useCurrentUser()
  const { updateCandidateProfile } = useCandidateProfile()
  const [working, setWorking] = useState(false)

  const isEmailEqualToFirstName = user && user.email.split('@')[0] === user.first_name
  const [userProfile, setUserProfile] = useState({
    first_name: isEmailEqualToFirstName ? '' : user?.first_name,
    last_name: user?.last_name || '',
    linkedin_url: user?.linkedin_url || '',
    visibility: null,
    did_onboarding: true,
  })

  const updateProfile = useMutation(
    (value: Partial<CurrentUserProfile>) => callApi(api.updateCurrentProfile, value),
    {
      onSuccess: () => {
        reloadUser()
      },
    },
  )
  const subscribeToLoops = useMutation(() => callApi(api.subscribeToLoops, 'sign-up'))
  const enrichProfileMutation = useMutation(
    (linkedin_url: string) => {
      return callApi(api.enrichProfile, linkedin_url)
    },
    {
      onSuccess: ({ profile }) => {
        if (!(profile.code && profile.code >= 400 && profile.code < 500)) {
          const experience = profile.experiences[0]
          let title = ''
          let company = ''

          if (experience) {
            title = experience.title ?? ''
            company = experience.company ?? ''
          }

          updateCandidateProfile({
            firstName: user.first_name || profile.first_name,
            lastName: user.last_name || profile.last_name,
            avatar: profile.profile_pic_url ?? '',
            location: profile.city || profile.country || undefined,
            headline: profile.headline ?? '',
            title,
            company,
            bio: profile.summary ?? '',
            workHistory: profile.experiences.map((experience) => ({
              uuid: experience.uuid,
              title: experience.title ?? '',
              company: experience.company ?? '',
              fromYear: experience.starts_at?.year ?? null,
              fromMonth: experience.starts_at?.month ?? null,
              toYear: experience.ends_at?.year ?? null,
              toMonth: experience.ends_at?.month ?? null,
              summary: experience.description ?? '',
            })),
            educationHistory: profile.education.map((education) => ({
              uuid: education.uuid,
              fieldOfStudy: education.field_of_study ?? '',
              school: education.school ?? '',
              summary: education.description ?? '',
              fromYear: education.starts_at?.year ?? null,
              fromMonth: education.starts_at?.month ?? null,
              toYear: education.ends_at?.year ?? null,
              toMonth: education.ends_at?.month ?? null,
            })),
          })
        } else {
          updateCandidateProfile({
            firstName: user.first_name,
            lastName: user.last_name,
          })
        }
      },
    },
  )

  const handleSubmit = async (value: Partial<CurrentUserProfile>) => {
    try {
      // First update profile and reload user
      await updateProfile.mutateAsync(value)
      await reloadUser()
      await subscribeToLoops.mutateAsync()

      Cookies.set(
        `candidate_profile_is_archived`,
        value.visibility === 'confidential' ? 'true' : 'false',
      )

      // Then handle LinkedIn enrichment if URL exists
      if (value.linkedin_url) {
        const linkedInSlug = getLinkedInSlug(value.linkedin_url)
        try {
          if (linkedinRequired) {
            await enrichProfileMutation.mutateAsync(linkedInSlug)
          } else {
            // Fire and forget if not required
            await enrichProfileMutation.mutate(linkedInSlug)
          }
        } catch (error) {
          console.error('LinkedIn enrichment failed:', error)
          // Continue with onboarding even if enrichment fails
        }
      }

      onContinue()
    } finally {
      setWorking(false)
    }
  }

  const personalInfoSchema = object({
    first_name: string().required('This field is required'),
    last_name: string().required('This field is required'),
    linkedin_url: linkedinRequired
      ? string()
          .required('This field is required')
          .matches(LINKED_IN_PROFILE_URL_REGEX, 'Please enter a valid LinkedIn profile URL')
      : string().matches(LINKED_IN_PROFILE_URL_REGEX, 'Please enter a valid LinkedIn profile URL'),
    visibility: string().nullable(),
  })

  return (
    <Card>
      <Header
        onBack={onBack}
        title={
          candidateOnboarding
            ? 'Set up your profile'
            : !candidateOnboarding && userProfile.linkedin_url != ''
            ? 'Confirm your profile'
            : 'Set up your profile'
        }
        subtitle={''}
      />
      <Formik
        initialValues={userProfile}
        validationSchema={personalInfoSchema}
        onSubmit={(value) => {
          setWorking(true)
          setUserProfile(value)
          handleSubmit(value)
        }}
      >
        {({ handleSubmit }) => {
          return (
            <Form
              onSubmit={handleSubmit}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  event.preventDefault()
                }
              }}
            >
              <InputWrapper>
                <Typography fontSize="14" className="flex-shrink-0 sm:w-[160px]">
                  First Name
                </Typography>
                <Field name="first_name">
                  {(props: FieldProps) => (
                    <TextInputField
                      {...props.field}
                      className="w-full"
                      placeholder="Enter First Name"
                      error={props.meta.touched ? props.meta.error : undefined}
                      data-testid="first-name"
                      autoFocus={props.field.value ? false : true}
                    />
                  )}
                </Field>
              </InputWrapper>
              <InputWrapper>
                <Typography fontSize="14" className="flex-shrink-0 w-[160px]">
                  Last Name
                </Typography>
                <Field name="last_name">
                  {(props: FieldProps) => (
                    <TextInputField
                      {...props.field}
                      className="w-full"
                      placeholder="Enter Last Name"
                      error={props.meta.touched ? props.meta.error : undefined}
                      data-testid="last-name"
                    />
                  )}
                </Field>
              </InputWrapper>
              <InputWrapper>
                <div className="flex flex-row sm:flex-col flex-shrink-0 sm:w-[160px] sm:items-start justify-between sm:justify-normal">
                  <Typography fontSize="14" className="">
                    LinkedIn URL
                  </Typography>
                  <CabalButton
                    variant="link"
                    type="button"
                    rightIcon={<i className="far fa-copy"></i>}
                    onClick={(e) => {
                      e.stopPropagation()
                      window.open('https://linkedin.com/in/', '_blank', 'noopener noreferrer')
                    }}
                    padding="0"
                    tabIndex={-1}
                  >
                    Get link
                  </CabalButton>
                </div>
                <Field name="linkedin_url">
                  {(props: FieldProps) => (
                    <TextInputField
                      error={props.meta.touched ? props.meta.error : undefined}
                      placeholder="LinkedIn URL"
                      autoFocus={userProfile.first_name && userProfile.last_name ? true : false}
                      {...props.field}
                      className="w-full"
                    />
                  )}
                </Field>
              </InputWrapper>

              {candidateOnboarding && (
                <InputWrapper>
                  <div className="flex flex-row sm:flex-col flex-shrink-0 sm:w-[160px] sm:items-start justify-between sm:justify-normal">
                    <Typography fontSize="14" className="flex-shrink-0">
                      Profile
                      <InfoTooltip className="ml-2">
                        <Typography lineHeight={1.2} fontSize="13" className="pb-1" component="p">
                          If you select “Visible,” the {team?.name} team may publish your profile on
                          a list. If you select “Confidential,” your profile will ONLY be accessible
                          by the {team?.name} team and will be hidden from lists.
                        </Typography>
                      </InfoTooltip>
                    </Typography>
                  </div>

                  <div className="w-full">
                    <Field name="visibility">
                      {(props: FieldProps) => (
                        <SelectField
                          placeholder="Hide my profile?"
                          fieldProps={props}
                          options={[
                            { label: 'Visible', value: 'visible' },
                            { label: 'Confidential', value: 'confidential' },
                          ]}
                          className="w-full"
                        />
                      )}
                    </Field>
                  </div>
                </InputWrapper>
              )}

              <ContinueWrapper>
                <CabalButton
                  className="mt-6 mb-2 self-end"
                  variant="primary"
                  type="submit"
                  disabled={working}
                >
                  {working ? 'Enriching...' : 'Continue'}
                </CabalButton>
              </ContinueWrapper>
            </Form>
          )
        }}
      </Formik>
    </Card>
  )
}

export default ProfileSetup
