import axios from 'axios'

import {
  ICreateMessageDraftPayload,
  IUpdateMessageDraftPayload,
} from 'containers/IntroRequestModal/components/types'
import { IRUpdatePayload } from 'containers/IntroRequestModal/types'

import {
  IFacilitateIntroRequest,
  IFacilitateIntroResponse,
  IFacilitatePublicIntroRequest,
} from 'utils/types/IFacilitateIntro'

export default {
  createIntroRequestV2: (params: IFacilitateIntroRequest) => {
    return axios.post<IFacilitateIntroResponse>(`/api/v2/intro_requests`, params)
  },
  createPublicIntroRequestV2: (params: IFacilitateIntroRequest) => {
    return axios.post<IFacilitateIntroResponse>(
      `/api/v2/intro_requests/create_from_public_page`,
      params,
    )
  },
  deleteIntroRequestV2: (id: string) => {
    return axios.delete(`/api/v2/intro_requests/${id}`)
  },
  offerIntroRequestV2: (id: string) => {
    return axios.post(`/api/v2/intro_requests/${id}/offer`)
  },
  getIntroRequestV2: (id: string | null) => {
    return axios.get<IFacilitateIntroResponse>(`/api/v2/intro_requests/${id}`)
  },
  archiveIntroRequestV2: (id: string, additionalNote: string) => {
    return axios.put(`/api/v2/intro_requests/${id}/archive`, { additional_note: additionalNote })
  },
  remindIntroRequestV2: (id: string, additionalNote?: string) => {
    return axios.put(`/api/v2/intro_requests/${id}/remind`, { additional_note: additionalNote })
  },
  newPublicIntroRequestV2: (
    team_slug: string,
    list_id: string,
    item_id: string,
    facilitator_key?: string,
  ) => {
    return axios.get<IFacilitatePublicIntroRequest>(`/api/v2/intro_requests/public_requests/new`, {
      params: { team_slug, list_id, item_id, facilitator_key },
    })
  },
  requestableIntroRequestV2: (
    id: string,
    point_of_contact_email: string,
    additionalNote: string,
    draftEmail: string,
    noteToFacilitator: string,
  ) => {
    return axios.put(`/api/v2/intro_requests/${id}/sent`, {
      point_of_contact_email: point_of_contact_email,
      additional_note: additionalNote,
      draft_email: draftEmail,
      note_to_facilitator: noteToFacilitator,
    })
  },
  sendToFacilitatorV2: ({ uuid, noteToFacilitator, status }: IRUpdatePayload) => {
    return axios.put(`/api/v2/intro_requests/${uuid}/send_to_facilitator`, {
      uuid: uuid,
      note_to_facilitator: noteToFacilitator,
      status: status,
    })
  },
  acceptIntroRequestV2: (id: string) => {
    return axios.put(`/api/v2/intro_requests/${id}/accept`)
  },
  declineIntroRequestV2: (id: string) => {
    return axios.put(`/api/v2/intro_requests/${id}/decline`)
  },
  getIntroRequestDeclinedReasons: () => {
    return axios.get(`/api/v2/intro_requests/declined_reasons`)
  },
  getIntroRequestReasons: (company_list_id?: string) => {
    return axios.get(`/api/v2/intro_requests/request_reasons`, { params: { company_list_id } })
  },
  updateDeclinedIntroRequestV2: (id: string, reason: string) => {
    return axios.put(`/api/v2/intro_requests/${id}/declined_reason`, { reason })
  },
  deleteMessageDraft: (messageUuid: string) => {
    return axios.delete(`/api/v2/intro_requests/messages/draft/${messageUuid}`)
  },
  createMessageDraft: (params: ICreateMessageDraftPayload) => {
    return axios.post(`/api/v2/intro_requests/messages/draft`, params)
  },
  updateMessageDraft: ({ messageUuid, sender_uuid }: IUpdateMessageDraftPayload) => {
    return axios.put(`/api/v2/intro_requests/messages/${messageUuid}`, { sender_uuid })
  },
  getIntroRequestActors: (facilitator_uuid: string, target_uuid: string) => {
    return axios.get(`/api/v2/intro_requests/actors`, {
      params: { facilitator: facilitator_uuid, requestable: target_uuid },
    })
  },
  getIntroRequestStatuses: () => {
    return axios.get(`/api/v2/intro_requests/statuses`)
  },
  createManualIntroRequest: (params: IFacilitateIntroRequest) => {
    return axios.post<IFacilitateIntroResponse>(`/api/v2/intro_requests/manual_intros`, params)
  },
}
