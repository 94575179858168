import React, { useEffect, useState } from 'react'

import { useMutation, useQuery, useQueryClient } from 'react-query'

import SavedSearchModal from 'containers/ListIndexView/Components/SavedSearchModal'
import CabalButton from 'global/CabalButton'
import Typography from 'global/Typography'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { CompanyListBlueprint } from 'utils/types'
import { SavedSearch } from 'utils/types/savedSearch'

interface SearchesProps {
  filterType: string
  filterCategory: string
  setTalentFiltersValue: (value: any) => void
  currentSearchResults: string[]
  appliedFilters: React.ReactNode[]
  companyList?: CompanyListBlueprint
  onSearchSaved?: (search: SavedSearch) => void
  handleSelectSearch?: (search: SavedSearch | null) => void
  savedSearch?: SavedSearch
}

export default function Searches({
  filterType,
  filterCategory,
  setTalentFiltersValue,
  currentSearchResults = [],
  appliedFilters = [],
  companyList,
  onSearchSaved,
  handleSelectSearch,
  savedSearch,
  setSavedSearch,
}: SearchesProps) {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [searchName, setSearchName] = useState<string>('')
  const [emailFrequency, setEmailFrequency] = useState<string>('never')
  const [isEdit, setIsEdit] = useState(false)

  const url = window.location.href
  const params = new URLSearchParams(new URL(url).search)
  const lastFragment = new URL(url).pathname.split('/').pop()

  const paramsObject = Array.from(params.entries()).reduce((acc, [key, value]) => {
    if (key in acc) {
      acc[key] = Array.isArray(acc[key]) ? [...acc[key], value] : [acc[key], value]
    } else {
      acc[key] = value
    }
    return acc
  }, {} as Record<string, string | string[]>)

  useEffect(() => {
    const currentFiltersLength = Object.keys(paramsObject).length
    if (savedSearch && currentFiltersLength !== Object.keys(savedSearch.query).length) {
      setSavedSearch(null)
      setSearchName('')
    }
  }, [paramsObject, savedSearch])

  const queryClient = useQueryClient()

  const saveMutation = useMutation(
    (payload: any) =>
      isEdit
        ? api.V2updateSavedSearch(savedSearch?.uuid, payload)
        : api.V2createSavedSearch(payload),
    {
      onSuccess: (response) => {
        console.log('response', response)
        if (response.status === 201 || response.status === 200) {
          queryClient.invalidateQueries('savedSearches')
          cabalToast({
            content: `Search ${isEdit ? 'updated' : 'saved'} successfully`,
            style: 'success',
          })
          onSearchSaved?.(response.data.saved_search)
          handleSelectSearch?.(response.data.saved_search)
          setSavedSearch(response.data.saved_search)
          setSearchName(response.data.saved_search.name)
        }
      },
      onError: () => {
        cabalToast({
          content: `Failed to ${isEdit ? 'update' : 'save'} search`,
          style: 'error',
        })
      },
    },
  )

  const handleSave = async () => {
    const payload = {
      name: searchName,
      query: paramsObject,
      email_frequency: emailFrequency,
      filter_type: filterType,
      filter_category: filterCategory,
      company_list_uuid: companyList?.uuid,
      results: currentSearchResults,
    }

    saveMutation.mutate(payload)
  }

  const handleDelete = () => {
    if (!savedSearch?.uuid) return

    if (confirm('Are you sure you want to delete this saved search?')) {
      deleteSavedSearch(savedSearch.uuid)
      setSavedSearch(null)
      setIsModalOpen(false)
      handleSelectSearch?.(null)
      queryClient.invalidateQueries('savedSearches')
    }
  }

  const { mutate: deleteSavedSearch } = useMutation(
    (uuid: string) => callApi(api.V2destroySavedSearch, uuid),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('savedSearches')
        cabalToast({
          content: 'Saved search deleted',
          style: 'success',
        })
      },
    },
  )

  return (
    <div className="flex gap-2.5">
      {savedSearch ? (
        <CabalButton
          variant="link"
          onClick={() => {
            setIsEdit(true)
            setSearchName(savedSearch.name)
            setEmailFrequency(savedSearch.email_frequency)
            setIsModalOpen(true)
          }}
          className="flex items-center gap-2"
        >
          <span>{savedSearch.name}</span>
          <Typography fontSize="14">
            <>
              <i className="far fa-pencil ml-2" />
            </>
          </Typography>
        </CabalButton>
      ) : (
        <CabalButton
          variant="link"
          onClick={() => {
            setIsEdit(false)
            setIsModalOpen(true)
          }}
        >
          Save Search
        </CabalButton>
      )}

      <SavedSearchModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        searchName={searchName}
        setSearchName={setSearchName}
        emailFrequency={emailFrequency}
        setEmailFrequency={setEmailFrequency}
        onSave={handleSave}
        onDelete={isEdit ? handleDelete : undefined}
        savedSearch={savedSearch}
        isEdit={isEdit}
        filters={appliedFilters}
      />
    </div>
  )
}
