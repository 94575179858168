import React, { useEffect, useState } from 'react'

import pluralize from 'pluralize'
import Skeleton from 'react-loading-skeleton'
import { useMutation, useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'

import { useSalesListContext } from 'containers/ListIndexView/SalesList'
import { ISalesCompany, ISalesPeople } from 'containers/ListIndexView/SalesList/type'
import { RequestIntroConnections } from 'containers/Portfolio/MemberView/RequestIntroConnections'
import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import { useModal } from 'global/Modal'
import { RenderModal } from 'global/Modal/Context'
import Typography from 'global/Typography'
import { useTeamSlug } from 'store/hooks'
import DataCardV2 from 'ui-components/DataCardV2'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import company from 'utils/api/company'
import { cn } from 'utils/styles'
import { AdvisorModel } from 'utils/types'

const SalesPeople = ({
  person,
  selectedPeople,
  setSelectedPeople,
}: {
  person: ISalesPeople
  selectedPeople: ISalesPeople[]
  setSelectedPeople: (selectedPeople: ISalesPeople[]) => void
}) => {
  const teamSlug = useTeamSlug()
  const { showModal } = useModal()

  const { companyListData, refetchCompanyList } = useSalesListContext()
  const [advisors, setAdvisrors] = useState([])

  const selected = !!selectedPeople.find((p) => p.uuid === person.uuid)

  const handleSelection = (person: ISalesPeople, selected: boolean) => {
    if (selected) {
      setSelectedPeople([...selectedPeople, person])
    } else {
      setSelectedPeople(selectedPeople.filter((a) => a.uuid !== person.uuid))
    }
  }

  const { mutate: deleteItem } = useMutation(
    () => api.removeElementFromSalesList(companyListData.uuid, [person.uuid]),
    {
      onSuccess: () => {
        refetchCompanyList()
        cabalToast({ style: 'success', content: 'Item has been removed from the list' })
      },
      onError: () => {
        cabalToast({ style: 'error', content: 'Failed to delete item' })
      },
    },
  )

  const getAdvisorQuery = useQuery(
    ['advisors', person.global_person_uuid],
    () => api.getConnectionsByUuid(person.global_person_uuid),
    {
      onSuccess: (data) => {
        setAdvisrors(data.data)
      },
    },
  )
  const menuItems = [
    {
      label: 'Delete',
      onSelect: () => deleteItem(),
    },
  ]

  const formatAdvisorName = (advisor: AdvisorModel) => {
    return advisor.last_name && advisor.first_name
      ? `${advisor.first_name} ${advisor.last_name?.charAt(0)}.`
      : advisor.name
  }

  const getSeparator = (index: number, totalDisplayed: number, hiddenCount: number) => {
    if (index < totalDisplayed - 1) return ', '
    return hiddenCount > 0 ? ' and ' : ''
  }

  const renderAdvisors = (advisors: AdvisorModel[]) => {
    if (getAdvisorQuery.isLoading) {
      return <Skeleton height={15} width={300} />
    }
    const showCount = 3
    const displayedAdvisors = advisors.slice(0, showCount)
    const hiddenAdvisorsCount = advisors.length - showCount

    return (
      <div className="flex flex-wrap gap-1 mt-2">
        <Typography component="button" color="link" data-testid="connection-advisors-link">
          <i className="far fa-arrows-left-right" />{' '}
          {displayedAdvisors.length === 0 && '0 Connections'}
          {displayedAdvisors.map((advisor, i) => (
            <span
              key={`advisor-${advisor.id}`}
              onClick={(e) => {
                e.stopPropagation()
                showModal(
                  (advisor, resolve) => renderSalesListModal(advisor, resolve),
                  'request-intro-connections-modal',
                )
              }}
            >
              {formatAdvisorName(advisor)}
              {getSeparator(i, displayedAdvisors.length, hiddenAdvisorsCount)}
            </span>
          ))}
          {hiddenAdvisorsCount > 0 && <span>and {hiddenAdvisorsCount} others</span>}
        </Typography>
      </div>
    )
  }

  const renderSalesListModal: RenderModal = (advisor, resolve) => (
    <RequestIntroConnections
      requestable={{
        uuid: person.global_person_uuid,
        full_name: person.name,
        name: person.name,
        email: person.email,
        first_name: person.first_name,
        headline: person.description,
        linkedin_url: person.profile_url,
        image_url: person.logo_url,
        item_type: 'GlobalPerson',
      }}
      companyList={companyListData}
      resolve={resolve}
      teamSlug={teamSlug}
      selectedAdvisor={advisor}
      facilitators={advisors}
    />
  )

  return (
    <DataCardV2
      onClick={() => showModal(renderSalesListModal, 'render_sales_list_modal')}
      key={person.uuid}
      title={person.name}
      description={
        <>
          <Typography
            color="rain_fog"
            fontSize="12"
            lineHeight={1}
            className="flex items-center gap-1.5"
          >
            {person.description}
          </Typography>
          {renderAdvisors(advisors)}
        </>
      }
      avatar={<Avatar src={person.avatar_url || person.logo_url} name={person.name} size={'32'} />}
      titleIcon={
        person.profile_url && (
          <Typography
            color="fog"
            fontSize="14"
            lineHeight={1}
            onClick={(e) => {
              e.stopPropagation()
              window.open(person.profile_url, '_blank')
            }}
          >
            <i className="fab fa-linkedin" />
          </Typography>
        )
      }
      checkbox={{
        checked: selected,
        onChange: (e) => {
          handleSelection(person, e.target.checked)
        },
      }}
      dropDownMenu={menuItems.length > 0 ? { menuItems: menuItems } : undefined}
      cta={
        <CabalButton
          onClick={() => showModal(renderSalesListModal, 'render_sales_list_modal')}
          variant={'link'}
          working={false}
          className="w-full"
        >
          {'Request Intro'}
        </CabalButton>
      }
      className={cn('h-[76px] rounded-none hover:rounded')}
    />
  )
}

interface Props {
  selectedPeople: ISalesPeople[]
  setSelectedPeople: (selectedPeople: ISalesPeople[]) => void
}

const SalesPeopleAdminView = ({ selectedPeople, setSelectedPeople }: Props) => {
  const { salesPeople } = useSalesListContext()
  return (
    <div>
      {salesPeople.map((salesPerson) => (
        <SalesPeople
          key={salesPerson.uuid}
          person={salesPerson}
          selectedPeople={selectedPeople}
          setSelectedPeople={setSelectedPeople}
        />
      ))}
    </div>
  )
}

export default SalesPeopleAdminView
