import React from 'react'

import cx from 'classnames'
import isEqual from 'lodash/isEqual'
import styled from 'styled-components'
import validator from 'validator'

import Typography from 'global/Typography'

import { getEnv, isExtension } from 'utils/env'

const DefaultAvatar = styled(Typography)`
  background-color: ${({ theme }) => theme.colors.mist};
`

interface AvatarState {
  sanitizedSrc?: string
  sanitizedSize?: string
  errored: boolean
}

export interface AvatarProps {
  size?: string
  src?: string
  isSrcDataUrl?: boolean
  name: string
  className?: string
  round?: boolean
}

class Avatar extends React.Component<AvatarProps, AvatarState> {
  state: AvatarState

  static defaultProps: Partial<AvatarProps> = {
    size: '32px',
    round: true,
  }

  constructor(props: AvatarProps) {
    super(props)

    const src = this.sanitizeSrc(props.src)
    if (src && !props.isSrcDataUrl) {
      this.downloadImage(src)
    }

    this.state = {
      sanitizedSrc: this.sanitizeSrc(props.src),
      sanitizedSize: this.sanitizeSize(props.size),
      errored: false,
    }
  }

  sanitizeSrc(str: string | undefined) {
    if (str && !validator.isURL(str) && !this.props.isSrcDataUrl) {
      const url = new URL(
        str,
        `${isExtension() ? getEnv('app_url') : window.location.protocol}//${window.location.host}`,
      )
      url.searchParams.set('v1', '1')
      return url.href
    }
    return str
  }

  sanitizeSize(str: string | undefined) {
    if (str && !str.match(/(px|rem|em)/)) {
      return str + 'px'
    }
    return str
  }

  downloadImage(src: string | undefined) {
    this.setState({ errored: false })
    if (src) {
      const dummyImage = document.createElement('img')
      dummyImage.src = src
      dummyImage.onerror = () => {
        this.setState({ errored: true })
      }
    }
  }

  componentDidUpdate(_: any, prevState: AvatarState): void {
    const newSanitizedSrc = this.sanitizeSrc(this.props.src)
    if (prevState.sanitizedSrc !== newSanitizedSrc) {
      this.setState({ sanitizedSrc: newSanitizedSrc })
      this.downloadImage(newSanitizedSrc)
    }

    const newSize = this.sanitizeSize(this.props.size)
    if (prevState.sanitizedSize !== newSize) {
      this.setState({ sanitizedSize: newSize })
    }
  }

  shouldComponentUpdate(
    nextProps: Readonly<AvatarProps>,
    nextState: Readonly<{}>,
    nextContext: any,
  ): boolean {
    return (
      !isEqual(this.state, nextState) ||
      this.props.src !== nextProps.src ||
      this.props.size !== nextProps.size ||
      this.props.name !== nextProps.name
    )
  }

  private backgroundColors = [
    'oklch(.637 .237 25.331)',
    'oklch(.705 .213 47.604)',
    'oklch(.769 .188 70.08)',
    'oklch(.681 .162 75.834)',
    'oklch(.648 .2 131.684)',
    'oklch(.6 .118 184.704)',
    'oklch(.715 .143 215.221)',
    'oklch(.588 .158 241.966)',
    'oklch(.585 .233 277.117)',
    'oklch(.667 .295 322.15)',
  ]

  private getRandomColor() {
    const index = Math.floor(Math.random() * this.backgroundColors.length)
    return this.backgroundColors[index]
  }

  render() {
    let content = <></>
    const { round, ...props } = this.props

    if (this.state.errored || !this.state.sanitizedSrc) {
      if (this.props.name) {
        content = (
          <svg
            width={this.state.sanitizedSize}
            height={this.state.sanitizedSize}
            viewBox={`0 0 100 100`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="100" height="100" fill={this.getRandomColor()} />
            <text
              fill="#fff"
              fontSize="45"
              x="50%"
              y="55%"
              dominantBaseline="middle"
              textAnchor="middle"
            >
              {this.props.name?.replace(/[^a-z0-9]/gi, '')?.[0]?.toUpperCase()}
            </text>
          </svg>
        )
      } else {
        content = (
          <DefaultAvatar
            color="fog"
            className="flex items-center justify-center rounded-full"
            style={{ width: `${this.props.size}px`, height: `${this.props.size}px` }}
          >
            <i className="far fa-user" />
          </DefaultAvatar>
        )
      }
    } else {
      content = (
        <img
          {...props}
          src={this.state.sanitizedSrc}
          style={{
            width: this.state.sanitizedSize,
            height: this.state.sanitizedSize,
            objectFit: 'contain',
          }}
        />
      )
    }

    return (
      <div
        className={cx(
          this.props.className,
          { 'rounded-full': round },
          'inline-block items-center align-middle overflow-hidden',
        )}
        style={{
          width: this.state.sanitizedSize,
          height: this.state.sanitizedSize,
        }}
      >
        {content}
      </div>
    )
  }
}

export default Avatar
