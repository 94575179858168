import React from 'react'

import moment from 'moment'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'

import MessageParsed from 'components/MessageParsed'
import NotesModal from 'containers/GlobalUserProfile/Modals/NotesModal'
import CabalButton from 'global/CabalButton'
import { useModal } from 'global/Modal'
import Typography from 'global/Typography'
import DataCardV2 from 'ui-components/DataCardV2'
import Widget from 'ui-components/Widget'

import api, { callApi } from 'utils/api'

const NoteRow = ({ note }: { note: any }) => {
  const { showModal } = useModal()
  const renderNotesModal = (resolve: () => void) => (
    <NotesModal onHide={resolve} viewOnly={true} note={note} />
  )

  const description = [
    <>{moment(note.updated_at).format('MMMM YYYY')}</>,
    <>{note.user.name}</>,
    <>{note.body.length > 30 ? `${note.body.substring(0, 70)}...` : note.body}</>,
  ]

  return (
    <DataCardV2
      avatar={<i className="fa-thin fa-note" />}
      title={<MessageParsed body={note.subject.split('\n')[0]} />}
      className="hover:bg-transparent dark:hover:bg-transparent"
      description={description}
      cta={
        <CabalButton
          variant="link"
          padding={'0'}
          onClick={() => showModal(renderNotesModal, 'notes_modal')}
        >
          View
        </CabalButton>
      }
    />
  )
}

const Notes = ({ canView }: { canView: boolean }) => {
  const { slug } = useParams()
  const [limit, setLimit] = React.useState(3)
  const { showModal } = useModal()

  const { data: notes = [] } = useQuery(
    ['globalPersonNotes', slug],
    () => callApi(api.getNotes, { slug }),
    {
      enabled: !!slug && canView,
    },
  )

  const renderNotesModal = (resolve: () => void) => (
    <NotesModal onHide={resolve} viewOnly={false} slug={slug} />
  )

  const sortedNotes = React.useMemo(
    () =>
      [...notes].sort(
        (a, b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime(),
      ),
    [notes],
  )

  const visibleNotes = React.useMemo(() => sortedNotes.slice(0, limit), [sortedNotes, limit])

  if (!canView) {
    return null
  }

  return (
    <Widget
      title="Notes"
      className="group"
      cta={
        <div className="group-hover:flex hidden flex-row gap-8">
          <CabalButton
            variant="link"
            padding={'0'}
            leftIcon={<i className="far fa-plus" />}
            onClick={() => showModal(renderNotesModal, 'notes_modal')}
          >
            Add
          </CabalButton>
        </div>
      }
    >
      {sortedNotes.length === 0 && (
        <div className="flex flex-col gap-4 items-center justify-center">
          <Typography color="rain_fog" fontSize="20" lineHeight={1}>
            <i className="far fa-sticky-note" />
          </Typography>
          <CabalButton variant="link" onClick={() => showModal(renderNotesModal, 'notes_modal')}>
            Add Note
          </CabalButton>
        </div>
      )}
      {visibleNotes.length > 0 && (
        <div className="-mx-2">
          {visibleNotes.map((note) => (
            <div key={note.uuid}>
              <NoteRow note={note} />
            </div>
          ))}
        </div>
      )}

      {sortedNotes.length > 3 && (
        <div className="flex justify-center pt-1">
          <CabalButton
            variant="link"
            onClick={() => (limit === 3 ? setLimit(sortedNotes.length) : setLimit(3))}
          >
            See all ({sortedNotes.length})
          </CabalButton>
        </div>
      )}
    </Widget>
  )
}

export default Notes
