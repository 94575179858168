import React, { useEffect, useState } from 'react'

import { useMutation, useQuery, useQueryClient } from 'react-query'

import CabalButton from 'global/CabalButton'
import Typography from 'global/Typography'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'

import SavedSearchModal from './SavedSearchModal'

interface SearchesProps {
  filterType: 'people' | 'jobs'
  filterCategory: 'talent'
  handleSelectSearch: (search: ISavedSearch) => void
  filtersValues: any
  setSavedSearch: (search: ISavedSearch) => void
}

interface ISavedSearch {
  uuid: string
  name: string
  query: Record<string, any>
  team_slug: string
  user_id: string
  email_frequency: 'never' | 'weekly' | undefined
  company_list_slug: string
  filter_type: string
  filter_category: string
  result_ids?: string[]
}

const SavedSearches = ({
  filterType,
  filterCategory,
  filtersValues,
  setSavedSearch,
  handleSelectSearch,
  savedSearch,
}: SearchesProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [editingSearch, setEditingSearch] = useState<ISavedSearch | null>(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [searchName, setSearchName] = useState('')
  const [emailFrequency, setEmailFrequency] = useState<'never' | 'weekly' | undefined>('never')

  const containerRef = React.useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const { data: savedSearchesData, refetch } = useQuery(
    'savedSearches',
    () =>
      callApi(api.V2indexSavedSearches, {
        filter_type: filterType,
        filter_category: filterCategory,
      }),
    {
      select: (data) => data.saved_searches,
    },
  )

  const { mutate: deleteSavedSearch } = useMutation(
    (uuid: string) => callApi(api.V2destroySavedSearch, uuid),
    {
      onSuccess: () => {
        refetch()
        cabalToast({
          content: 'Saved search deleted',
          style: 'success',
        })
      },
    },
  )

  const { mutate: updateSavedSearch } = useMutation(
    ({ uuid, saved_search }: { uuid: string; saved_search: Partial<ISavedSearch> }) =>
      callApi(api.V2updateSavedSearch, uuid, {
        saved_search,
      }),
    {
      onSuccess: (data) => {
        refetch()
        cabalToast({
          content: 'Saved search updated successfully',
          style: 'success',
        })
        setSavedSearch(data.saved_search)
        setIsModalOpen(false)
      },
      onError: () => {
        cabalToast({
          content: 'Failed to update saved search',
          style: 'error',
        })
      },
    },
  )

  const handleDeleteSearch = (uuid: string) => {
    if (confirm('Are you sure you want to delete this saved search?')) {
      const searchToDelete = savedSearchesData?.find((search: ISavedSearch) => search.uuid === uuid)
      if (searchToDelete) {
        deleteSavedSearch(uuid)
        setIsModalOpen(false)
        setEditingSearch(null)
        handleSelectSearch(searchToDelete)
        setSavedSearch(null)
      }
    }
  }

  const handleEdit = (search: ISavedSearch) => {
    setEditingSearch(search)
    setSearchName(search.name)
    setEmailFrequency(search.email_frequency)
    setIsModalOpen(true)
  }

  const queryClient = useQueryClient()

  const handleSave = () => {
    queryClient.invalidateQueries('savedSearches')

    if (editingSearch) {
      updateSavedSearch({
        uuid: editingSearch.uuid,
        saved_search: {
          ...editingSearch,
          name: searchName,
          email_frequency: emailFrequency,
        },
      })
    }
  }

  // TODO: https://github.com/getcabal/cabal/pull/7571/files#diff-9c3a866f033bc8622555e23f8f3662b064a1bea524499579785ec9b69466bc14 Use this logic.

  const addFiltersToUrl = (search: ISavedSearch) => {
    setSavedSearch(search)

    if (search?.query) {
      const newFilters = {}
      Object.entries(search.query).forEach(([key, value]) => {
        newFilters[key] = value
      })

      handleSelectSearch(search)
    }
  }

  return (
    <div className="saved-searches-container relative" ref={containerRef}>
      <Typography
        className="cursor-pointer"
        color="purple"
        fontSize="12"
        onClick={() => {
          setIsOpen(!isOpen)
        }}
      >
        Saved searches
      </Typography>

      {isOpen && (
        <div className="flex flex-col gap-2 absolute top-full left-0 bg-white dark:bg-gray-800 shadow-lg rounded-md p-2 mt-1 z-10 min-w-[275px]">
          {savedSearchesData && savedSearchesData.length > 0 ? (
            savedSearchesData.map((search: ISavedSearch) => (
              <div
                key={search.uuid}
                className="flex items-center justify-between p-2 hover:bg-gray-50 dark:hover:bg-gray-700 rounded w-full"
              >
                <div
                  className="cursor-pointer hover:text-blue-600 dark:hover:text-blue-400 flex-grow pr-4"
                  onClick={() => {
                    addFiltersToUrl(search)
                    setSavedSearch(search)
                  }}
                >
                  <Typography fontSize="14">{search.name}</Typography>
                </div>
                <div className="flex">
                  <CabalButton variant="link" onClick={() => handleEdit(search)} className="pr-0">
                    <Typography fontSize="14">
                      <>
                        <i className="far fa-pencil" />
                      </>
                    </Typography>
                  </CabalButton>
                  <CabalButton variant="link" onClick={() => handleDeleteSearch(search.uuid)}>
                    <Typography
                      fontSize="14"
                      className="text-red-500 hover:text-red-700 dark:text-red-400 dark:hover:text-red-300 cursor-pointer"
                    >
                      <>
                        <i className="far fa-trash-alt" />
                      </>
                    </Typography>
                  </CabalButton>
                </div>
              </div>
            ))
          ) : (
            <Typography fontSize="14" className="p-2">
              No searches saved
            </Typography>
          )}
        </div>
      )}

      <SavedSearchModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        searchName={searchName}
        setSearchName={setSearchName}
        setSavedSearch={setSavedSearch}
        emailFrequency={emailFrequency}
        setEmailFrequency={setEmailFrequency}
        onSave={handleSave}
        savedSearch={savedSearch}
        onDelete={() => {
          if (editingSearch) {
            handleDeleteSearch(editingSearch.uuid)
          }
        }}
        isEdit={!!editingSearch}
        filters={filtersValues}
      />
    </div>
  )
}

export default SavedSearches
