import React, { useState } from 'react'

import { useMutation } from 'react-query'

import CabalButton from 'global/CabalButton'
import { Select, TextArea } from 'global/Input'
import Modal from 'global/Modal'
import { ModalInputLabel, ModalInputWrapper } from 'global/Modal/styles'
import Typography from 'global/Typography'
import { useCurrentUser } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { PersonBlueprint } from 'utils/types'

interface OfferIntroModalProps {
  person: PersonBlueprint
  onHide: () => void
}

const OfferIntroModal: React.FC<OfferIntroModalProps> = ({ person, onHide }) => {
  const [selectedTeam, setSelectedTeam] = useState('')
  const [note, setNote] = useState('')
  const { user } = useCurrentUser()

  const teams = user.advisors.map((advisor) => ({
    value: advisor.team_slug,
    label: advisor.team_name,
  })) || [{}]

  const createVote = useMutation(
    () =>
      callApi(api.createVote, {
        votable_type: 'GlobalPerson',
        votable_id: person.uuid,
        team_slug: selectedTeam,
        notes: note,
        vote: 1,
      }),
    {
      onSuccess: () => {
        const selectedTeamObj = teams.find((team) => team.value === selectedTeam)
        cabalToast({
          content: `Your offer to introduce ${person.full_name} has been logged. ${selectedTeamObj?.label} will follow up with you via email.`,
          style: 'success',
        })
        onHide()
      },
      onError: (error) => {
        console.error('Error creating vote:', error)
      },
    },
  )

  const handleSubmit = () => {
    createVote.mutate()
  }

  return (
    <Modal
      show={true}
      header={`Introduce ${person.full_name} to a team or person`}
      onHide={onHide}
      rightActions={
        <CabalButton
          variant="primary"
          onClick={handleSubmit}
          disabled={!selectedTeam || !note || createVote.isLoading}
          working={createVote.isLoading}
        >
          Send Offer
        </CabalButton>
      }
    >
      <ModalInputWrapper className="py-2">
        <ModalInputLabel>Select Team</ModalInputLabel>
        <Select
          options={teams}
          value={selectedTeam}
          onChange={(value) => setSelectedTeam(value)}
          placeholder="Select a team..."
        />
      </ModalInputWrapper>

      <ModalInputWrapper className="py-2">
        <ModalInputLabel>Note</ModalInputLabel>
        <TextArea
          value={note}
          onChange={(e) => setNote(e.target.value)}
          placeholder="Add a note about this intro offer..."
          rows={4}
        />
      </ModalInputWrapper>
    </Modal>
  )
}

export default OfferIntroModal
