import React, { useMemo } from 'react'

import { useMutation, useQueryClient } from 'react-query'
import { useLocation } from 'react-router-dom'

import { useComposer } from 'components/Composer'
import BulkActionsIsland from 'containers/ListIndexView/Components/BulkActionsIsland'
import { useJobsListContext } from 'containers/ListIndexView/TalentList/JobsList'
import JobsListBulkActionModal from 'containers/ListIndexView/TalentList/JobsList/JobsListBulkActionModal'
import { IJob } from 'containers/ListIndexView/TalentList/JobsList/types'
import { useModal } from 'global/Modal'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { GetInvestorTagsResponse } from 'utils/types/investor'

interface Props {
  selectedJobs: IJob[]
  jobTags?: GetInvestorTagsResponse
  setSelectedJobs: (jobs: IJob[]) => void
}

const JobsListBulkActions: React.FC<Props> = ({ selectedJobs, jobTags, setSelectedJobs }) => {
  const { showModal } = useModal()
  const { adminView } = useJobsListContext()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const archivedStatus = searchParams.get('status') === 'archived'
  const { compose } = useComposer()
  const {
    companyListData: companyList,
    refetchListItems,
    view,
    setView,
    refetchCompanyList,
  } = useJobsListContext()
  const queryClient = useQueryClient()

  const selectedJobsUuids = useMemo(
    () => selectedJobs.map((job) => job.uuid).filter((uuid): uuid is string => uuid !== undefined),
    [selectedJobs],
  )

  const renderJobsListBulkActionModal = (resolve: () => void, header: string, kind: string) => (
    <JobsListBulkActionModal
      onHide={() => resolve()}
      refetch={refetchListItems}
      selectedJobsUuids={selectedJobsUuids}
      jobTags={jobTags}
      header={header}
      kind={kind}
      companyListUuid={companyList.uuid}
    />
  )

  const { mutate: bulkAction } = useMutation(
    (params: any) =>
      callApi(api.jobsBulkActions, companyList.uuid, {
        selected_jobs_uuids: selectedJobsUuids,
        bulk_action: params.action,
        kind: params.kind,
        values: [],
      }),
    {
      onSuccess: (_, params) => {
        refetchListItems()
        queryClient.invalidateQueries(['jobsForAdd', companyList.uuid])
        refetchCompanyList()
        cabalToast({ style: 'success', content: `Successfully ${params.action}d!` })
        if (params.action === 'delete' || params.action === 'archive') {
          setSelectedJobs([])
        }
      },
      onError: () => {
        cabalToast({
          style: 'error',
          content: 'Something went wrong, please try again or contact support',
        })
      },
    },
  )

  const handleOnSelect = (header: string, action: string, kind: string) => {
    if (selectedJobs.length === 0) {
      cabalToast({
        content: 'Select companies to perform bulk actions',
        style: 'error',
      })
      return
    }
    if (action === 'delete') {
      const confirmDelete = window.confirm('Are you sure you want to bulk delete?')
      if (confirmDelete) {
        bulkAction({ action, kind })
      }
    } else if (action === 'archive' || action === 'restore') {
      bulkAction({ action, kind })
    } else if (action === 'share') {
      handleShare()
    } else {
      showModal(
        (resolve) => renderJobsListBulkActionModal(resolve, header, kind),
        'render_jobs_list_bulk_actions',
      )
    }
  }

  const handleShare = () => {
    callApi(api.getInsertsByUuids, {
      filter_by: 'uuids',
      uuids: selectedJobsUuids,
    }).then((data) => {
      const filteredData = data.filter((item: any) => selectedJobsUuids.includes(item.uuid))
      const htmlContent = filteredData.map((item: any) => item.insert_content).join('')
      compose({
        body: htmlContent,
      })
    })
  }

  const bulkMenuItems = [
    {
      title: 'Add Tags',
      onClick: () => handleOnSelect('Add Tags', 'add', 'tags'),
      icon: <i className="far fa-tag" />,
    },
    {
      title: 'Share',
      onClick: () => handleOnSelect('Share', 'share', 'share'),
      icon: <i className="far fa-arrow-up-from-bracket" />,
    },
    {
      title: 'Delete Tags',
      onClick: () => handleOnSelect('Delete Tags', 'delete', 'tags'),
      icon: <i className="far fa-trash-alt" />,
    },
    {
      title: 'Delete Jobs',
      onClick: () => handleOnSelect('Delete Jobs', 'delete', 'jobs'),
      icon: <i className="far fa-trash-alt" />,
    },
    {
      title: `${archivedStatus ? 'Restore' : 'Archive'} Jobs`,
      onClick: () => handleOnSelect('Archive Jobs', archivedStatus ? 'restore' : 'archive', 'jobs'),
      icon: <i className="far fa-archive" />,
    },
  ]

  return (
    <>
      {adminView && (
        <span>
          <BulkActionsIsland
            isOpen={selectedJobs.length > 0}
            setIsOpen={() => setSelectedJobs([])}
            bulkMenuItems={bulkMenuItems}
            itemCount={selectedJobs.length}
          />
        </span>
      )}
    </>
  )
}

export default JobsListBulkActions
