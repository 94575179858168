import React, { useState } from 'react'

import styled from 'styled-components'

import { ensureFullUrl } from '../utils/url'

const AvatarWrapper = styled.div<{ size: string; backgroundColor?: string }>`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  border-radius: 50%;
  overflow: hidden;
  background-color: ${(props) => props.backgroundColor || '#e5e7eb'};
  display: flex;
  align-items: center;
  justify-content: center;
`

const AvatarImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const AvatarInitials = styled.span<{ size: string }>`
  color: white;
  font-weight: 300;
  font-size: ${(props) => {
    // Remove any non-numeric characters (px, rem, etc)
    const sizeNum = parseInt(props.size.replace(/[^0-9]/g, ''))
    // Set font size to ~40% of the avatar size
    return `${Math.max(sizeNum * 0.4, 10)}px`
  }};
`

interface AvatarProps {
  src?: string
  name?: string
  size?: string
  className?: string
}

const Avatar: React.FC<AvatarProps> = ({ src, name = '', size = '40px', className = '' }) => {
  const [imageError, setImageError] = useState(false)

  const getInitials = (name: string) => {
    const firstLetter = name.replace(/[^\p{L}]/gu, '').charAt(0)
    return firstLetter.toUpperCase()
  }

  const getBackgroundColor = (name: string) => {
    const colors = [
      '#2563eb', // blue
      '#7c3aed', // purple
      '#db2777', // pink
      '#dc2626', // red
      '#059669', // green
      '#d97706', // yellow
      '#4f46e5', // indigo
      '#7c2d12', // orange
    ]

    // Generate a consistent index based on the name string
    const index = Array.from(name).reduce(
      (acc, char) => (Math.imul(31, acc) + char.charCodeAt(0)) | 0,
      0,
    )

    return colors[Math.abs(index) % colors.length]
  }

  const handleImageError = () => {
    setImageError(true)
  }

  const backgroundColor = !src || imageError ? getBackgroundColor(name) : undefined

  return (
    <AvatarWrapper size={size} className={className} backgroundColor={backgroundColor}>
      {src && !imageError ? (
        <AvatarImage src={ensureFullUrl(src)} alt={name} onError={handleImageError} />
      ) : (
        <AvatarInitials size={size}>{getInitials(name)}</AvatarInitials>
      )}
    </AvatarWrapper>
  )
}

export default Avatar
