import React from 'react'

import Tracking from 'containers/Settings/Company/Tracking'

import { Team } from 'utils/types'

import FooterToggle from './FooterToggle'

export interface TeamSettingProps {
  team: Team
  reloadTeam: () => Promise<any>
  updateTeam: (team: Partial<Team>) => void
  updatingTeam: boolean
}

const Company = () => {
  return (
    <div className="flex flex-col gap-4 mt-4">
      <Tracking />
      <FooterToggle />
    </div>
  )
}

export default Company
