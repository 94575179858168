import styled from 'styled-components'

interface ButtonProps {
  variant?: 'primary' | 'secondary'
  fullWidth?: boolean
  disabled?: boolean
}

export const Button = styled.button<ButtonProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  transition: background-color 0.2s;
  border: none;
  width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};

  ${(props) =>
    props.variant === 'primary' &&
    `
    background-color: #5c69d1;
    color: white;
    &:hover {
      background-color: #4a56b9;
    }
  `}

  ${(props) =>
    props.variant === 'secondary' &&
    `
    background-color: #f3f4f6;
    color: #374151;
    &:hover {
      background-color: #e5e7eb;
    }
  `}
`

Button.defaultProps = {
  variant: 'primary',
  type: 'button',
}
