import * as React from 'react'

import isArray from 'lodash/isArray'
import objectHash from 'object-hash'
import { useInfiniteQuery } from 'react-query'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import tw from 'twin.macro'
import { IterableElement } from 'type-fest'

import { useComposeDealBoardDraft } from 'components/Composer/hooks'
import { Divider } from 'components/SendMessage/styles'
import ConnectionRow from 'containers/Asks/DealBoard/ConnectionRow'
import AdvisorPill from 'containers/Connections/AdvisorPill'
import { useBulkIntroRequest } from 'containers/ListView/Companies/BulkIntroRequest'
import ConnectionsTableRow from 'containers/ListView/People/ConnectionsTableRow'
import { useAccessControl } from 'global/AccessControl'
import CabalButton from 'global/CabalButton'
import EmptyState from 'global/EmptyState'
import Loading from 'global/Loading'
import Typography from 'global/Typography'
import { useCurrentUser, useTeam } from 'store/hooks'

import api, { callApi } from 'utils/api'
import useColorMode from 'utils/hooks/useColorMode'
import { CompaniesFiltersType, CompanyConnectionsResponse } from 'utils/types'

import GlobalPerson from './GlobalPerson'

interface BlockItemProps {
  item_id: string
  company: {
    uuid: string
    id: string
  }
}

interface Props {
  teamSlug: string
  blockItem: BlockItemProps
  icps?: string
  filters?: CompaniesFiltersType
  companyListName: string
  listUuid?: string
}

const CompanyVoteArea = styled.div`
  ${tw`flex flex-col sm:flex-row gap-4 pb-2`}
  border-bottom: ${({ theme }) => theme.border};
`

const CompanyVotesLogo = styled(Typography)`
  width: 38px;
  padding-left: 10px;
  text-align: center;
`

const GlobalPersonListContainer = styled.div`
  ${tw`flex py-2 text-xs pt-0`}
`

const ItemDetails: React.FC<Props> = (props: Props) => {
  const { companyListName, teamSlug, blockItem, icps, listUuid, filters = {} } = props
  const history = useHistory()
  const location = useLocation()
  const { isAdminOrEmployee, canAddAdvisor } = useAccessControl(teamSlug)
  const { composeDealBoardDraft } = useComposeDealBoardDraft()
  const { isMobile } = useColorMode()
  const {
    selectGlobalPerson,
    deselectGlobalPerson,
    isGlobalPersonSelected: isSelected,
  } = useBulkIntroRequest(blockItem.item_id)
  const { user } = useCurrentUser()

  const [hidden, setHidden] = React.useState<number[]>([])
  const company_votes = [] as const

  const hidePerson = React.useCallback((person_id: number) => {
    setHidden([...hidden, person_id])
  }, [])

  const unhidePerson = React.useCallback(
    (person_id: number) => {
      setHidden(hidden.filter((i) => i !== person_id))
    },
    [hidden],
  )

  const {
    data: companyConnectionsData,
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    [teamSlug, 'companies', blockItem.item_id, 'connections', icps, objectHash(filters)],
    ({ pageParam: page = 1 }) =>
      callApi(
        api.getCompanyConnections,
        blockItem.item_id,
        page,
        icps,
        undefined,
        filters,
        location.pathname.split('/')[3],
        teamSlug,
      ),
    {
      keepPreviousData: true,
      getNextPageParam: (lastPage) => lastPage.pagination.next_page,
      getPreviousPageParam: (firstPage) => firstPage.pagination.prev_page,
    },
  )

  const conn = useInfiniteQuery(
    [teamSlug, 'connections', filters.advisor_uuids],
    ({ pageParam: page = 1 }) =>
      callApi(
        api.getConnections,
        [companyListName],
        undefined,
        filters.advisor_uuids || [],
        teamSlug,
        false,
        page,
        blockItem.item_id,
      ),
    {
      cacheTime: 0,
      enabled: user.team?.slug === teamSlug,
      getNextPageParam: (lastPage) => lastPage.pagination.next_page,
      getPreviousPageParam: (firstPage) => firstPage.pagination.prev_page,
    },
  )

  const allConnectionsPeople = React.useMemo(
    () => conn?.data?.pages?.flatMap((page) => page.people) ?? [],
    [conn],
  )

  const people = React.useMemo(
    () => companyConnectionsData?.pages.flatMap((page) => page.people),
    [companyConnectionsData],
  )
  const users = React.useMemo(
    () => companyConnectionsData?.pages.flatMap((page) => page.users),
    [companyConnectionsData],
  )

  const renderGlobalPerson = React.useCallback(
    ({ person, connections }: IterableElement<CompanyConnectionsResponse['people']>) => {
      return (
        <GlobalPerson
          connections={connections}
          key={blockItem.item_id + '-' + person.id}
          users={users!}
          person={person}
          requestable={blockItem.company}
          teamSlug={teamSlug}
          hidePerson={hidePerson}
          unhidePerson={unhidePerson}
          hidden={hidden.includes(person.id)}
          personSelected={isSelected(person)}
          listUuid={listUuid}
          showCheckbox
          togglePersonCheckbox={() => {
            if (isSelected(person)) {
              deselectGlobalPerson(person)
            } else {
              selectGlobalPerson(person)
            }
          }}
        />
      )
    },
    [
      blockItem.company,
      blockItem.item_id,
      deselectGlobalPerson,
      hidden,
      hidePerson,
      isSelected,
      selectGlobalPerson,
      teamSlug,
      unhidePerson,
      users,
    ],
  )

  const shareIntroDraftToAdvisor = (user_uuid: string) => {
    composeDealBoardDraft({
      user_uuid: user_uuid,
      company_id: blockItem.item_id,
      teamSlug: teamSlug,
    })
  }

  const connectedPeople = React.useMemo(
    () => people?.filter((p) => p.person.current_user_connected === true && !p.person.former_on),
    [people],
  )

  const otherPeople = React.useMemo(
    () => people?.filter((p) => p.person.current_user_connected === false && !p.person.former_on),
    [people],
  )

  const formerConnectedPeople = React.useMemo(
    () => people?.filter((p) => p.person.current_user_connected === true && p.person.former_on),
    [people],
  )

  const formerOtherPeople = React.useMemo(
    () => people?.filter((p) => p.person.current_user_connected === false && p.person.former_on),
    [people],
  )

  const renderAllPeople = React.useMemo(() => {
    return (
      <>
        {connectedPeople && connectedPeople.length > 0 && (
          <div className="mt-5">
            <Typography className="-mb-4" component="p" color="fog">{`My Connections`}</Typography>
            <Divider />
            {connectedPeople.map((p) => renderGlobalPerson(p))}
          </div>
        )}
        {isAdminOrEmployee && otherPeople && otherPeople.length > 0 && (
          <div className="mt-5">
            <Typography
              className="-mb-4"
              component="p"
              color="fog"
            >{`Member Connections`}</Typography>
            <Divider />
            {otherPeople.map((p) => renderGlobalPerson(p))}
          </div>
        )}
        {isAdminOrEmployee && formerConnectedPeople && formerConnectedPeople.length > 0 && (
          <div className="mt-5">
            <Typography
              className="-mb-4"
              component="p"
              color="fog"
            >{`My connections to former ${companyListName} employees`}</Typography>
            <Divider />
            {formerConnectedPeople.map((p) => renderGlobalPerson(p))}
          </div>
        )}
        {isAdminOrEmployee && formerOtherPeople && formerOtherPeople.length > 0 && (
          <div className="mt-5">
            <Typography
              className="-mb-4"
              component="p"
              color="fog"
            >{`Member connections to former ${companyListName} employees`}</Typography>
            <Divider />
            {formerOtherPeople.map((p) => renderGlobalPerson(p))}
          </div>
        )}
        {isAdminOrEmployee &&
          allConnectionsPeople &&
          allConnectionsPeople.length > 0 &&
          !hasNextPage && (
            <div className="mt-5">
              <Typography
                className="-mb-4"
                component="p"
                color="fog"
              >{`All Connections with "${companyListName}" in their info`}</Typography>
              <Divider />
              {allConnectionsPeople.map((person) => (
                <ConnectionsTableRow
                  key={(person.id || person.uuid) + person.name}
                  person={person}
                  advisors={person.advisors}
                  companySlug={teamSlug}
                  hideAvatar={true}
                />
              ))}
            </div>
          )}
      </>
    )
  }, [
    allConnectionsPeople,
    companyListName,
    connectedPeople,
    hasNextPage,
    otherPeople,
    renderGlobalPerson,
    teamSlug,
  ])

  return (
    <>
      {isFetching && <Loading className="m-6" />}

      {!isLoading && (!!people?.length || company_votes?.length > 0) && (
        <GlobalPersonListContainer>
          <div className="w-full">
            {company_votes && company_votes.length > 0 && (
              <CompanyVoteArea>
                {!isMobile && (
                  <CompanyVotesLogo color="primary" fontSize="18" fontWeight={600}>
                    <i className="far fa-building"></i>
                  </CompanyVotesLogo>
                )}
                <div className="flex flex-col gap-3">
                  <div className="flex flex-col gap-2 sm:gap-0">
                    <div className="flex items-center gap-3">
                      {isMobile && (
                        <CompanyVotesLogo color="primary" fontSize="18" fontWeight={600}>
                          <i className="far fa-building"></i>
                        </CompanyVotesLogo>
                      )}
                      <Typography color="primary" fontSize="14" fontWeight={600}>
                        Company Offers
                      </Typography>
                    </div>
                    <Typography color="fog_rain" fontSize="14" component="div">
                      These offers are not associated with a specific person
                    </Typography>
                  </div>
                  <div className="flex gap-1">
                    {company_votes.map((vote) => (
                      <AdvisorPill
                        advisor={vote.user}
                        key={vote.user.uuid}
                        teamSlug={teamSlug}
                        status={'liked'}
                        onCtaClick={() => shareIntroDraftToAdvisor(vote.user.uuid)}
                      />
                    ))}
                  </div>
                </div>
              </CompanyVoteArea>
            )}

            {renderAllPeople}

            {hasNextPage && (
              <div className="flex justify-center mt-4">
                <CabalButton
                  variant="secondary"
                  onClick={() => fetchNextPage()}
                  working={isFetchingNextPage}
                >
                  Load more
                </CabalButton>
              </div>
            )}

            {conn?.hasNextPage && !hasNextPage && (
              <div className="flex justify-center mt-4">
                <CabalButton
                  variant="secondary"
                  onClick={() => conn.fetchNextPage()}
                  working={conn.isFetchingNextPage}
                >
                  Load more
                </CabalButton>
              </div>
            )}
          </div>
        </GlobalPersonListContainer>
      )}
      {!isLoading && !isFetching && !(!!people?.length || company_votes?.length > 0) && (
        <div>
          <EmptyState
            heading={
              <Typography fontSize="14" fontWeight={600}>
                No Connections Found
              </Typography>
            }
            icon={<i className="fal fa-user-magnifying-glass"></i>}
            body={
              <div className="flex items-center justify-center mt-0.5">
                {canAddAdvisor && (
                  <Typography fontSize="12">
                    <Typography
                      color="purple"
                      className="cursor-pointer"
                      onClick={() => history.push(`/${teamSlug}/import`)}
                    >
                      Add Member{' '}
                    </Typography>

                    <Typography>to expand your reach</Typography>
                  </Typography>
                )}
              </div>
            }
          />
        </div>
      )}
    </>
  )
}

export default ItemDetails
