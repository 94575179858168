import React from 'react'

import Typography from 'global/Typography'
import PillV2 from 'ui-components/PillV2'

interface ProfileFiltersProps {
  filter: string
  onSetFilter: (filter: string) => void
  settings: {
    hasAbout: boolean
    hasCandidateProfile: boolean
    profileOwner: boolean
    hasExperience: boolean
    hasEducation: boolean
    hasNotes: boolean
    hasFiles: boolean
  }
}

const ProfileFilters = ({ filter, onSetFilter, settings }: ProfileFiltersProps) => {
  const { profileOwner, hasExperience, hasEducation, hasNotes, hasFiles } = settings

  return (
    <div className="flex flex-wrap gap-2">
      <PillV2
        title={
          <Typography fontSize="12" lineHeight={1}>
            All
          </Typography>
        }
        active={filter === ''}
        onClick={() => onSetFilter('')}
      />

      {(profileOwner || hasExperience) && (
        <PillV2
          title={
            <Typography fontSize="12" lineHeight={1}>
              <i className={`far fa-briefcase mr-1.5`}></i>
              Experience
            </Typography>
          }
          active={filter === 'experience'}
          onClick={() => onSetFilter('experience')}
        />
      )}

      {(profileOwner || hasEducation) && (
        <PillV2
          title={
            <Typography fontSize="12" lineHeight={1}>
              <i className={`far fa-graduation-cap mr-1.5`}></i>
              Education
            </Typography>
          }
          active={filter === 'education'}
          onClick={() => onSetFilter('education')}
        />
      )}

      {/* <PillV2
        title={
          <Typography fontSize="12" lineHeight={1}>
            <i className={`far fa-handshake mr-1.5`}></i>
            Agreements
          </Typography>
        }
        active={filter === 'agreements'}
        onClick={() => onSetFilter('agreements')}
      /> */}

      {(!profileOwner || hasNotes) && (
        <PillV2
          title={
            <Typography fontSize="12" lineHeight={1}>
              <i className={`far fa-sticky-note mr-1.5`}></i>
              Notes
            </Typography>
          }
          active={filter === 'notes'}
          onClick={() => onSetFilter('notes')}
        />
      )}

      {(profileOwner || hasFiles) && (
        <PillV2
          title={
            <Typography fontSize="12" lineHeight={1}>
              <i className={`far fa-file mr-1.5`}></i>
              Files
            </Typography>
          }
          active={filter === 'files'}
          onClick={() => onSetFilter('files')}
        />
      )}

      {/* <PillV2
        title={
          <Typography fontSize="12" lineHeight={1}>
            <i className={`far fa-arrows-left-right mr-1.5`}></i>
            Connections
          </Typography>
        }
        active={filter === 'connections'}
        onClick={() => onSetFilter('connections')}
      /> */}
      {/* <PillV2
        title={
          <Typography fontSize="12" lineHeight={1}>
            <i className={`far fa-briefcase mr-1.5`}></i>
            Portfolio
          </Typography>
        }
        active={filter === 'portfolio'}
        onClick={() => onSetFilter('portfolio')}
      /> */}
    </div>
  )
}

export default ProfileFilters
