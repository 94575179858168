import React from 'react'

import styled from 'styled-components'

interface TypographyProps {
  children: React.ReactNode
  component?: keyof JSX.IntrinsicElements
  fontSize?: string | number
  fontWeight?: number
  color?: 'default' | 'green' | 'gray' | 'fog' | 'fog_rain' | 'purple'
  lineHeight?: string | number
  textAlign?: 'left' | 'center' | 'right'
}

const StyledTypography = styled.div<Omit<TypographyProps, 'component'>>`
  font-size: ${(props) =>
    typeof props.fontSize === 'number' ? `${props.fontSize}px` : props.fontSize || '14px'};
  font-weight: ${(props) => props.fontWeight || 400};
  line-height: ${(props) => props.lineHeight || 1.5};
  text-align: ${(props) => props.textAlign || 'left'};
  color: ${(props) => {
    switch (props.color) {
      case 'green':
        return '#22C55E'
      case 'gray':
        return '#6B7280'
      case 'fog':
        return '#9CA3AF'
      case 'fog_rain':
        return '#718096'
      case 'purple':
        return '#8A97FF'
      default:
        return 'inherit'
    }
  }};
`

const Typography: React.FC<TypographyProps> = ({ component = 'div', children, ...props }) => {
  return (
    <StyledTypography as={component} {...props}>
      {children}
    </StyledTypography>
  )
}

export default Typography
