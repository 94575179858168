import React, { useEffect, useRef, useState } from 'react'

import Cookies from 'js-cookie'
import Expand from 'react-expand-animated'
import { useQuery } from 'react-query'
import { useMutation } from 'react-query'
import styled from 'styled-components'

import DraftEmail from 'containers/IntroRequestModal/components/DraftEmail'
import EmailSelectorV2 from 'containers/IntroRequestModal/components/EmailSelectorV2'
import FacilitatedBy from 'containers/IntroRequestModal/components/FacilitatedBy'
import PointOfContactInput from 'containers/IntroRequestModal/components/PointOfContactInput'
import UsersInfo from 'containers/IntroRequestModal/components/UsersInfo'
import { IJob } from 'containers/ListIndexView/TalentList/JobsList/types'
import ArchiveModal from 'containers/Portfolio/MemberView/ArchiveModal'
import { IntroConnection } from 'containers/Portfolio/MemberView/types/IntroConnection'
import { FacilitateIntroModalNote } from 'containers/Portfolio/styles'
import { useAccessControl } from 'global/AccessControl'
import CabalButton from 'global/CabalButton'
import { TextArea } from 'global/Input'
import Modal, { useModal } from 'global/Modal'
import CKEditor from 'global/TextEditor/ckeditor'
import Typography from 'global/Typography'
import { useCurrentUser, useTeam } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { setJobIntroRequestCookie } from 'utils/cookieUtils'
import { absoluteHtmlContent } from 'utils/html'
import { CompanyBlueprint, CompanyListBlueprint, PersonBlueprint } from 'utils/types'
import { IFacilitateIntroRequest, IFacilitateIntroResponse } from 'utils/types/IFacilitateIntro'

interface IProps {
  requestable: CompanyBlueprint | PersonBlueprint | IJob
  companyList?: CompanyListBlueprint
  gatekeeperId?: number
  facilitator: IntroConnection
  introRequest?: any
  resolve: () => void
  includeInitialNote?: boolean
  networkingOnly?: boolean
  requestType?: string
  disableSend?: boolean
  companySlug?: string
}

const TruncatedContent = styled.div`
  &.truncated {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`

const SeeMoreButton = styled(Typography)`
  cursor: pointer;
  display: none;
  /* Only show when parent div has overflow content */
  div[data-has-overflow='true'].truncated + & {
    display: block;
  }
`

const NoteContainer = styled.div`
  & .ck-sticky-panel__content {
    border-width: 0px !important;
  }
`
export function FacilitateIntroModal({
  requestable,
  companyList,
  gatekeeperId,
  facilitator,
  introRequest,
  resolve,
  includeInitialNote,
  facilitatorKey,
  networkingOnly = false,
  requestType,
  disableSend = false,
  companySlug,
}: IProps) {
  const {
    secret_uuid: introRequestUuid = null,
    team_slug: introRequestTeamSlug = null,
    user: requestingUser = null,
    note: requestedNote = null,
  } = introRequest ?? {}
  const { user } = useCurrentUser()
  const requestor: {
    name: string | undefined
    avatarUrl: string | undefined
    title: string | undefined
    uuid: string | undefined
    teamName: string | undefined
  } = {
    name: '',
    avatarUrl: '',
    title: '',
    uuid: '',
    teamName: '',
  }

  const [submitting, setSubmitting] = useState(false)

  const internalRequest = introRequest?.request_type === 'internal' || requestType === 'internal'

  const { isAdminOrEmployee } = useAccessControl(companyList?.owning_team?.slug)
  const {
    data: gatekeeperData,
    isSuccess: gatekeeperFetchSuccess,
    isLoading: gatekeeperFetchLoading,
  } = useQuery({
    queryKey: ['gatekeeper', gatekeeperId],
    queryFn: () => callApi(api.getGateKeeper, gatekeeperId),
    enabled: gatekeeperId != 0,
  })

  const gatekeeper = internalRequest ? gatekeeperData : companyList?.gatekeeper

  if (
    requestingUser &&
    introRequestUuid &&
    (introRequest?.request_type === 'advisor' || introRequest?.request_type === 'non_advisor')
  ) {
    requestor.name = requestingUser?.name || ''
    requestor.avatarUrl = requestingUser.avatar_url
    requestor.title = requestingUser.title || ''
    requestor.uuid = requestingUser.uuid
    requestor.teamName = requestingUser?.team_name || ''
  } else if (requestable?.item_type === 'InvestorCompanyJob') {
    requestor.name = requestingUser?.name || user?.name
    requestor.avatarUrl = requestingUser?.avatar_url || user?.avatar_url || ''
    requestor.title = requestingUser?.title || user?.title || ''
    requestor.uuid = requestingUser?.uuid || user.uuid
    requestor.teamName = gatekeeper?.team_name || requestingUser?.team_name || user?.team?.name
  } else if (introRequest?.request_type === 'internal' || requestType === 'internal') {
    requestor.name = gatekeeper?.name
    requestor.avatarUrl = gatekeeper?.avatar_url
    requestor.title = gatekeeper?.title || ''
    requestor.uuid = gatekeeper?.uuid
    requestor.teamName = gatekeeper?.team_name
  } else {
    requestor.name = user.name
    requestor.avatarUrl = user.avatar_url
    requestor.title = user.title || ''
    requestor.uuid = user.uuid
    requestor.teamName = user?.team?.name
  }

  const logo_url = 'https://logo.clearbit.com/'

  const getTargetAvatarUrl = (company: CompanyBlueprint | PersonBlueprint | IJob) => {
    if (company.item_type === 'other' && company.avatar_upload_uuid) {
      return `/api/uploads/${company.avatar_upload_uuid}`
    } else if (company.type === 'GlobalPerson') {
      return company?.logo_url || company?.avatar_url || company?.image_url
    } else if (company.item_type === 'Company' || company.item_type === 'company') {
      return company?.logo_url || company?.avatar_url || logo_url + company?.domain
    } else if (company.item_type === 'InvestorCompanyJob') {
      return company?.investor_company?.domain
        ? logo_url + company?.investor_company?.domain
        : logo_url + company?.domain
    }
  }

  const recipientItemType = requestable?.item_type || requestable?.type

  const targetAvatarUrl = getTargetAvatarUrl(requestable)

  const facilitatorObject = {
    name: facilitator?.name || '',
    avatarUrl: facilitator?.avatar_url || facilitator?.logo || '',
    uuid: facilitator?.uuid,
  }

  let target
  if (requestable?.item_type === 'InvestorCompanyJob') {
    target = {
      name: requestable?.investor_company?.company_name || requestable?.name || '',
      company: requestable?.investor_company?.company_name || requestable?.company_name || '',
      headline: requestable?.title || requestable?.headline || requestable?.description || '',
      uuid: requestable?.requestable_uuid || requestable?.global_person_uuid || requestable?.uuid,
      avatarUrl: targetAvatarUrl,
      itemType: recipientItemType,
      description: requestable?.description,
      point_of_contact_email: requestable?.point_of_contact_email || requestable?.email,
    }
  } else {
    target = {
      name: requestable?.name || '',
      company: requestable?.company_name || '',
      headline: requestable?.headline || requestable?.description || '',
      uuid: requestable?.global_person_uuid || requestable?.uuid || requestable?.requestable_uuid,
      avatarUrl: targetAvatarUrl,
      itemType: recipientItemType,
      description: requestable?.description,
      point_of_contact_email: requestable?.point_of_contact_email || requestable?.email,
    }
  }

  const requestorObject = {
    name: requestor.name || '',
    teamName: requestor.teamName || '',
    avatarUrl: requestor.avatarUrl || '',
    title: requestor.title || '',
  }

  const [note, setNote] = useState<string>(introRequest?.note_to_gatekeeper || '')
  const [isNoteExpanded, setIsNoteExpanded] = useState(false)
  const contentRef = useRef<HTMLDivElement>(null)
  const [hasOverflow, setHasOverflow] = useState(false)

  const [noteToFacilitator, setNoteToFacilitator] = useState<string>('')

  const [checkedDraftEmail, setCheckedDraftEmail] = useState<boolean>(true)

  const teamSlug = companyList?.owning_team?.slug || companySlug
  const { canViewTeam } = useAccessControl(teamSlug)

  const { team } = useTeam(teamSlug)

  const enableTeamIntroRequest = team?.features?.enable_intro_requests_v2

  const listSlug = companyList?.slug || companyList

  let introRequestType: string

  if (introRequest) {
    introRequestType = introRequest.request_type
  } else if (teamSlug === user?.team?.slug) {
    introRequestType = 'internal'
  } else if (canViewTeam) {
    introRequestType = 'advisor'
  } else {
    introRequestType = 'non_advisor'
  }

  const createRequestPayload = () => {
    let facilitatorUuid = null
    if (
      companyList?.list_type === 'resources' ||
      companyList?.list_type === 'talent' ||
      companyList?.list_type === 'people'
    ) {
      facilitatorUuid = null
    } else {
      facilitatorUuid = facilitator.uuid
    }
    const payload = {
      team_slug: teamSlug,
      company_list_id: listSlug,
      note: note,
      draft_email: enableTeamIntroRequest && checkedDraftEmail ? draftEmail : null,
      requestable_id: target.uuid,
      facilitator_user_uuid:
        !canViewTeam && introRequestType === 'non_advisor' ? facilitatorKey : facilitatorUuid,
      requestable_type: target.itemType,
      gatekeeper_id: gatekeeperId || null,
      additional_note: additionalNote || null,
      request_type: introRequestType,
      note_to_gatekeeper: note || null,
      note_to_facilitator: noteToFacilitator || null,
      requestor_uuid: requestor.uuid,
      initiator_uuid: user.uuid,
      point_of_contact_email: target.point_of_contact_email || null,
    }
    if (checkedDraftEmail) {
      return { ...payload, draftEmail }
    }

    return payload
  }

  const { mutate: introRequestMutate } = useMutation({
    mutationFn: (payload: IFacilitateIntroRequest) => callApi(api.createIntroRequestV2, payload),
  })

  const { mutate: archiveIntroRequestMutate } = useMutation({
    mutationFn: (uuid: string) => callApi(api.archiveIntroRequestV2, uuid),
  })

  const { mutate: requestableIntroRequestMutate } = useMutation({
    mutationFn: (uuid: string) =>
      callApi(
        api.requestableIntroRequestV2,
        uuid,
        email,
        additionalNote,
        draftEmail,
        noteToFacilitator,
      ),
  })

  const { closeModal, showModal } = useModal()

  const handleSubmit = () => {
    setSubmitting(true)
    if (introRequestUuid === null) {
      const payload = createRequestPayload()
      introRequestMutate(payload, {
        onSuccess: (data: IFacilitateIntroResponse) => {
          if (
            data.request_type === 'non_advisor' &&
            (data.requestable_type === 'InvestorCompanyJob' || data.request_reason === 'job')
          ) {
            setJobIntroRequestCookie(data)
          }
          cabalToast({ style: 'success', content: 'Intro requested!', fadeOut: false })
          closeModal('facilitate-intro-modal')
          setSubmitting(false)
        },
        onError: () => {
          console.log('Error')
          setSubmitting(false)
        },
      })
    } else {
      requestableIntroRequestMutate(introRequestUuid, {
        onSuccess: () => {
          cabalToast({ style: 'success', content: 'Intro sent!' })
          closeModal('facilitate-intro-modal')
          setSubmitting(false)
        },
      })
    }
    closeModal('request-intro-connections-modal')
  }

  const handleArchive = () => {
    archiveIntroRequestMutate(introRequestUuid, {
      onSuccess: () => {
        cabalToast({ style: 'success', content: 'Intro archived!' })
        closeModal('facilitate-intro-modal')
      },
      onError: () => {
        console.log('Error')
      },
    })
  }

  const renderArchiveModal = (resolve) => (
    <ArchiveModal
      onHide={() => resolve()}
      introRequestUuid={introRequestUuid}
      name={target.name}
      requestorName={requestor.name}
    />
  )

  const [draftEmail, setDraftEmail] = useState<string>(introRequest?.draft_email || '')

  const handleDraftEmail = (e: string) => {
    setDraftEmail(e)
  }

  const handleCheckedDraft = (checked: boolean) => {
    setCheckedDraftEmail(checked)
    if (checked === false) {
      setDraftEmail('')
    } else {
      setDraftEmail(draftEmail)
    }
  }

  const currentUserGatekeeper = introRequest?.gatekeeper_uuid === user.uuid

  const buttonText =
    (companyList?.gatekeeper?.uuid && isAdminOrEmployee) || !introRequest
      ? `Send request to ${
          internalRequest
            ? currentUserGatekeeper
              ? introRequest
                ? facilitator?.name
                : companyList?.gatekeeper?.name || facilitator?.name || target.name
              : requestor?.uuid == user.uuid
              ? facilitator?.name
              : requestor?.name
            : introRequest
            ? target.name
            : facilitator?.name || team?.name
        }`
      : `Submit request to ${
          internalRequest
            ? companyList?.gatekeeper?.name || target.name
            : target?.name || team?.name
        } `

  const disableEmailInput = introRequest?.requestable?.point_of_contact_email !== null
  const [email, setEmail] = useState<string>(
    introRequest?.requestable?.point_of_contact_email ||
      requestable?.point_of_contact_email ||
      requestable?.email ||
      '',
  )

  const canSeeAdditionalNote = introRequest?.facilitator?.uuid === user.uuid
  const [showAdditionalNote, setShowAdditionalNote] = useState(true)
  const handleShowAdditionalNote = () => {
    setShowAdditionalNote(!showAdditionalNote)
  }
  const [additionalNote, setAdditionalNote] = useState<string>('')

  const canSeeDraftEmail = internalRequest && team?.features?.can_see_draft_email_on_intro_request

  const showEmailsSelector =
    (introRequest?.gatekeeper_uuid === user.uuid &&
      companyList?.enable_gatekeeper &&
      (requestable?.item_type === 'GlobalPerson' ||
        requestable?.item_type === 'InvestorCompanyJob')) ||
    (isAdminOrEmployee &&
      (requestable?.item_type === 'GlobalPerson' ||
        requestable?.item_type === 'InvestorCompanyJob') &&
      introRequest)

  const newPointOfContactEmail = (e: string) => {
    setEmail(e)
  }

  let noteText =
    (internalRequest && introRequest) || requestor?.uuid != user.uuid
      ? requestor?.name
      : facilitator?.name

  if (requestable?.item_type === 'InvestorCompanyJob') {
    noteText = requestable?.investor_company?.company_name || requestable?.name || ''
  }

  const showNoteToGatekeeper = introRequest == null || !internalRequest
  const showNoteToFacilitator = introRequest?.gatekeeper_uuid === user.uuid
  const canEditNoteToFacilitator = introRequest?.user?.uuid === user.uuid

  const handleCloseModal = () => {
    closeModal('facilitate-intro-modal')
    closeModal('request-intro-connections-modal')
  }

  const isNoteTextAreaForGatekeeperDisabled =
    introRequest?.user?.uuid !== user.uuid && introRequestUuid !== null

  const isTalentJobs = companyList?.list_category === 'talent' && companyList?.list_type === 'jobs'

  useEffect(() => {
    const observer = new MutationObserver((mutations, obs) => {
      const element = contentRef.current
      if (element) {
        // Element exists, start watching for resize
        const resizeObserver = new ResizeObserver(() => {
          const hasOverflowContent = element.scrollHeight > element.clientHeight
          setHasOverflow(hasOverflowContent)
        })

        resizeObserver.observe(element)
        obs.disconnect() // Stop watching for DOM changes

        // Cleanup resize observer when component unmounts
        return () => resizeObserver.disconnect()
      }
    })

    // Start observing the document for when our element appears
    observer.observe(document.body, {
      childList: true,
      subtree: true,
    })

    return () => observer.disconnect()
  }, [note])

  if (gatekeeperId != 0 && gatekeeperFetchLoading) {
    return <div>Loading ...</div>
  }

  return (
    <Modal
      show
      className="max-w-lg"
      onHide={handleCloseModal}
      header={`Intro to ${target.name}`}
      centerActions={
        <div className="flex items-center justify-center flex-col gap-2">
          <CabalButton
            onClick={handleSubmit}
            disabled={disableSend || submitting}
            tooltip={disableSend ? 'Members will be able to click this button' : undefined}
          >
            {buttonText}
          </CabalButton>
          {/* Make if statement if the introRequestUuid Exists then add this btn */}
          {introRequestUuid && (
            <>
              <CabalButton
                variant={'tertiary'}
                onClick={() => {
                  showModal(renderArchiveModal, 'archive-modal')
                  closeModal('facilitate-intro-modal')
                }}
              >
                Archive (with optional note to candidate)
              </CabalButton>
            </>
          )}
        </div>
      }
    >
      <UsersInfo requestor={requestor} target={target}></UsersInfo>

      <FacilitatedBy facilitator={facilitatorObject} introRequest={introRequest} />

      {(companyList?.list_category == 'portfolio' || isTalentJobs) && introRequest && (
        <PointOfContactInput
          target={target}
          pointOfContactEmail={email}
          inputEmail={(e) => newPointOfContactEmail(e)}
          disabled={disableEmailInput}
        ></PointOfContactInput>
      )}

      {networkingOnly && (
        <div className="mt-6 mb-6 text-center">
          <Typography fontSize={'16'} color={'rain'} fontWeight={600}>
            <i className="fa fa-info-circle mr-1" /> {requestable?.first_name || target.name} is
            only looking to network
          </Typography>
        </div>
      )}
      <div>
        {showNoteToFacilitator && internalRequest && (
          <>
            <Typography fontSize="12" fontWeight={400} lineHeight="1" className=" text-center">
              Note for {facilitator?.name}
            </Typography>
            {!canEditNoteToFacilitator ? (
              <Typography
                component="p"
                fontSize="12"
                fontWeight={400}
                lineHeight="1.2"
                color="fog"
                className="pl-4 pr-4 pt-4 "
              >
                <FacilitateIntroModalNote>
                  <span dangerouslySetInnerHTML={{ __html: noteToFacilitator }} />
                </FacilitateIntroModalNote>
              </Typography>
            ) : (
              <CKEditor
                value={noteToFacilitator}
                onChange={(val) => setNoteToFacilitator(absoluteHtmlContent(val) || '')}
                height={100}
                width={'100%'}
                placeholder="Enter your note here..."
                companySlug={teamSlug}
              />
            )}
          </>
        )}
        {showNoteToGatekeeper && (
          <>
            <Typography fontSize="12" fontWeight={400} lineHeight="1" className=" text-center">
              Note
              {introRequest?.user?.uuid === user.uuid || introRequest === undefined
                ? ` for ${noteText}`
                : ` from ${introRequest?.user?.name}`}
            </Typography>
            {isNoteTextAreaForGatekeeperDisabled ? (
              <Typography
                component="p"
                fontSize="12"
                fontWeight={400}
                lineHeight="1.2"
                color="fog"
                className="pl-4 pr-4 pt-2"
              >
                <TruncatedContent
                  ref={contentRef}
                  className={!isNoteExpanded ? 'truncated' : ''}
                  data-has-overflow={hasOverflow}
                >
                  <span dangerouslySetInnerHTML={{ __html: note }} />
                </TruncatedContent>
                <SeeMoreButton
                  className="cursor-pointer"
                  color="purple"
                  onClick={() => setIsNoteExpanded(!isNoteExpanded)}
                >
                  {isNoteExpanded ? 'See less' : 'See more'}
                </SeeMoreButton>
              </Typography>
            ) : (
              <NoteContainer>
                <CKEditor
                  value={note}
                  onChange={(val) => setNote(absoluteHtmlContent(val) || '')}
                  height={100}
                  minHeight={'75px'}
                  width={'100%'}
                  placeholder="Enter your note here..."
                  companySlug={teamSlug}
                />
              </NoteContainer>
            )}
          </>
        )}

        {showEmailsSelector && !internalRequest && !isTalentJobs && (
          <EmailSelectorV2
            email={email}
            setEmail={setEmail}
            target={target}
            teamSlug={teamSlug!}
            list={companyList}
          ></EmailSelectorV2>
        )}
        {canSeeDraftEmail && enableTeamIntroRequest && (
          <DraftEmail
            teamSlug={teamSlug}
            emailBody={draftEmail}
            facilitator={facilitatorObject}
            target={target}
            requestor={requestorObject}
            canSeeDraftEmail={true}
            checked={(checked: boolean) => handleCheckedDraft(checked)}
            selectedDraftEmail={(email: string) => handleDraftEmail(email)}
          ></DraftEmail>
        )}
      </div>
      {canSeeAdditionalNote && (
        <div className="pt-2">
          <CabalButton variant={'link'} className="-ml-4" onClick={handleShowAdditionalNote}>
            Private note to {target.name}
            {showAdditionalNote ? (
              <i className="far fa-chevron-down fa-xs ml-2" />
            ) : (
              <i className="far fa-chevron-right fa-xs ml-2" />
            )}
          </CabalButton>

          <Expand open={showAdditionalNote}>
            <div className="mb-3">
              <TextArea
                value={additionalNote}
                className="w-full mt-1"
                rows={3}
                data-testid="request-talent-intro-modal-context"
                onChange={(e) => setAdditionalNote(e.target.value)}
                placeholder={'Add private note'}
                disabled={false}
              />
            </div>
          </Expand>
        </div>
      )}
    </Modal>
  )
}
