import React from 'react'

import { GlobalPerson } from 'app/javascript/hooks/useGlobalPerson'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'

import HeaderModal from 'containers/GlobalUserProfile/Modals/HeaderModal'
import OfferIntroModal from 'containers/GlobalUserProfile/Modals/OfferIntroModal'
import { RequestIntroConnections } from 'containers/Portfolio/MemberView/RequestIntroConnections'
import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import { useModal } from 'global/Modal'
import Typography from 'global/Typography'
import { useCurrentUser } from 'store/hooks'

import api, { callApi } from 'utils/api'
import { CandidateProfileHeader, PersonBlueprint } from 'utils/types'

import InviteGlobalPersonModal from './Modals/InviteGlobalPersonModal'

interface HeaderProps {
  person: GlobalPerson | PersonBlueprint
  canEdit: boolean
}

interface ConnectionStatus {
  cabal_connection_exist: boolean
  cabal_connection_accepted: boolean
  linkedin_connection: boolean
}

const Header: React.FC<HeaderProps> = ({ person, canEdit }) => {
  const { slug } = useParams<{ slug: string }>()
  const { showModal } = useModal()
  const { user } = useCurrentUser()
  const queryClient = useQueryClient()

  const { data: profile } = useQuery<CandidateProfileHeader>(
    ['getGlobalPersonHeader', slug],
    () => callApi(api.getGlobalPersonHeader, { slug }),
    {
      enabled: !!slug,
    },
  )

  const { data: advisors = [] } = useQuery(
    ['globalPersonConnections', person.uuid],
    () => callApi(api.getConnectionsByUuid, person.uuid),
    {
      enabled: !!person.uuid,
    },
  )

  const { data: connectionStatus, error: connectionError } = useQuery<ConnectionStatus>(
    ['connectionStatus', person.uuid],
    () => callApi(api.getConnectionStatus, { slug: person.uuid }),
    {
      enabled: !!person.uuid,
    },
  )

  const createConnection = useMutation(() => callApi(api.createConnection, { slug: person.uuid }), {
    onSuccess: () => {
      queryClient.invalidateQueries(['connectionStatus', person.uuid])
    },
  })

  const handleConnectionClick = () => {
    if (!connectionStatus?.cabal_connection_exist) {
      createConnection.mutate()
    }
  }

  const renderHeaderModal = (resolve: () => void) => <HeaderModal onHide={resolve} info={person} />

  const renderFacilitateIntroModal = (resolve: () => void) => (
    <RequestIntroConnections
      requestable={person}
      resolve={resolve}
      teamSlug={user.team?.slug}
      advisors={advisors}
      showLists={true}
    />
  )

  const renderOfferIntroModal = (resolve: () => void) => (
    <OfferIntroModal person={person} onHide={resolve} />
  )

  const renderInviteModal = (resolve: () => void) => (
    <InviteGlobalPersonModal person={person} onHide={resolve} />
  )

  const renderConnectionButton = () => {
    if (connectionError?.response?.status === 404) {
      return (
        <CabalButton
          variant="secondary"
          leftIcon={<i className="far fa-envelope" />}
          onClick={() => showModal(renderInviteModal, 'invite-global-person-modal')}
        >
          Invite
        </CabalButton>
      )
    }

    if (connectionStatus?.cabal_connection_exist && !connectionStatus?.cabal_connection_accepted) {
      return (
        <CabalButton variant="secondary" leftIcon={<i className="far fa-clock" />} disabled>
          Pending
        </CabalButton>
      )
    }

    if (!connectionStatus?.cabal_connection_exist) {
      return (
        <CabalButton
          variant="secondary"
          leftIcon={<i className="far fa-plus" />}
          onClick={handleConnectionClick}
          working={createConnection.isLoading}
        >
          Connect
        </CabalButton>
      )
    }

    return null
  }

  return (
    <div className="mt-4 flex items-center justify-between">
      <div className="flex">
        <div className="flex flex-shrink-0">
          <Avatar src={person.image_url} name={person.full_name} size={'92'} />
        </div>

        <div className="flex flex-col ml-4">
          <div className="flex items-baseline gap-2">
            <Typography lineHeight={1.25} fontSize="36" fontWeight={600}>
              {person.full_name}
            </Typography>
            <Typography fontSize="20" color="purple" lineHeight={1}>
              <i className="fa fa-badge-check fa-fw -mr-1" />
            </Typography>

            {person.linkedin_url && (
              <Typography
                component="a"
                color="fog"
                fontSize="20"
                href={person.linkedin_url}
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-linkedin"></i>
              </Typography>
            )}
            {/* <Typography
              component="a"
              color="fog"
              fontSize="20"
              href={''}
              target="_blank"
              rel="noreferrer"
            >
              <i className="fab fa-x-twitter"></i>
            </Typography> */}
          </div>
          <p>
            <Typography fontSize="16" color="fog" className="mt-1">
              {person.headline}
            </Typography>
          </p>
          <p>
            <Typography fontSize="14" color="fog" className="mt-1">
              <i className="far fa-location-dot mr-1 fa-sm"></i>
              {profile?.location}
            </Typography>
          </p>
        </div>
      </div>

      <div className="flex flex-shrink-0 items-baseline gap-3">
        {canEdit ? (
          <CabalButton
            variant="link"
            leftIcon={<i className="far fa-pen-to-square" />}
            onClick={() => showModal(renderHeaderModal, 'header_modal')}
          >
            <Typography fontSize="12">Edit</Typography>
          </CabalButton>
        ) : (
          <>
            {renderConnectionButton()}
            {connectionStatus?.cabal_connection_accepted ||
            connectionStatus?.linkedin_connection ? (
              <CabalButton
                variant="primary"
                leftIcon={<i className="far fa-handshake" />}
                onClick={() => showModal(renderOfferIntroModal, 'offer-intro-modal')}
              >
                Offer Intro
              </CabalButton>
            ) : (
              <CabalButton
                variant="primary"
                leftIcon={<i className="far fa-thumbs-up" />}
                onClick={() =>
                  showModal(renderFacilitateIntroModal, 'request-intro-connections-modal')
                }
              >
                Request Intro
              </CabalButton>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default Header
