enum errorMessages {
  invalidImage = 'Invalid file type. Please select a PNG or a JPEG.',
  invalidEmail = 'Invalid Email.',
  fileUpload = "Your file couldn't be uploaded. Please try again later.",
  domainDpFind = 'Sorry, we could not find an image. Please upload logo manually.',
  emailDpFind = 'Sorry, we could not find an image. Please upload logo manually.',
  usersUpload = 'There was a problem uploading the users. Please try again later.',
  inviteUser = 'There was a problem inviting the user. Please try again later.',
  invalidUrl = 'Please input a valid URL with a protocol. For eg: https://getcabal.com',
}

export default errorMessages
